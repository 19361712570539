import { combineReducers } from 'redux';

import { initial } from '../initial';
import * as NS from '../../namespace';

export function dataReducer(state: NS.IReduxState['data'] = initial.data, action: NS.Action) {
    switch (action.type) {

    case 'APP:GET_AUTHENTICATED_STATUS': {
        return {
            ...state,
            isAuthenticated: action.payload
        };
    }

    default:
        return state;
    }
}

const reducer = combineReducers<NS.IReduxState>({
    data: dataReducer
});

export default reducer;
