import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { selectSelectedFilters } from '../../../../services/filters/redux/selectors';
import { IDependencies } from '../../../../shared/types/app';
import { getErrorMsg } from '../../../../shared/helpers';

import { selectNextPage, selectSortType } from '../selectors';
import * as actions from '../actions';
import * as NS from '../../namespace';

const loadCompaniesType: NS.ILoadCompanies['type'] = 'COMPANIES_LIST:LOAD_COMPANIES';
const sortCompaniesType: NS.ISortCompanies['type'] = 'COMPANIES_LIST:SORT_COMPANIES';
const filterCompaniesType: NS.IFilterCompanies['type'] = 'COMPANIES_LIST:FILTER_COMPANIES';
const loadFavoriteCompaniesType: NS.ILoadFavoriteCompanies['type'] = 'COMPANIES_LIST:LOAD_FAVORITE_COMPANIES';
const addCompanyToFavoriteType: NS.IAddCompanyToFavorite['type'] = 'COMPANIES_LIST:ADD_COMPANY_TO_FAVORITE';
const deleteCompanyFromFavoriteType: NS.IDeleteCompanyFromFavorite['type'] = 'COMPANIES_LIST:DELETE_COMPANY_FROM_FAVORITE';
const loadNoteFromServerType: NS.ILoadNoteFromServer['type'] = 'COMPANIES_LIST:LOAD_NOTE_FROM_SERVER';
const saveNoteOnServerType: NS.ISaveNoteOnServer['type'] = 'COMPANIES_LIST:SAVE_NOTE_ON_SERVER';
const sendFeedbackType: NS.ISendFeedback['type'] = 'COMPANIES_LIST:SEND_FEEDBACK_MESSAGE';

function* loadCompaniesSaga({ api }: IDependencies) {
    try {
        const nextPage = yield select(selectNextPage);
        const sortBy = yield select(selectSortType);
        const filters = yield select(selectSelectedFilters);

        const companies = yield call(api.companies.loadCompanies, nextPage, sortBy, filters);
        yield put(actions.loadCompaniesCompleted(companies));
    } catch (error) {
        const errorMsg = getErrorMsg(error);
        yield put(actions.loadCompaniesFailed(errorMsg));
    }
}

function* sortCompaniesSaga({ api }: IDependencies) {
    try {
        const nextPage = null;
        const sortBy = yield select(selectSortType);
        const filters = yield select(selectSelectedFilters);

        const companies = yield call(api.companies.loadCompanies, nextPage, sortBy, filters);
        yield put(actions.sortCompaniesCompleted(companies));
    } catch (error) {
        const errorMsg = getErrorMsg(error);
        yield put(actions.sortCompaniesFailed(errorMsg));
    }
}

function* filterCompaniesSaga({ api }: IDependencies) {
    try {
        const nextPage = null;
        const sortBy = yield select(selectSortType);
        const filters = yield select(selectSelectedFilters);

        const companies = yield call(api.companies.loadCompanies, nextPage, sortBy, filters);
        yield put(actions.filterCompaniesCompleted(companies));
    } catch (error) {
        const errorMsg = getErrorMsg(error);
        yield put(actions.filterCompaniesFailed(errorMsg));
    }
}

function* loadFavoriteCompaniesSaga({ api }: IDependencies) {
    try {
        const favorites = yield call(api.companies.loadFavoriteCompanies);
        yield put(actions.loadFavoriteCompaniesCompleted(favorites));
    } catch (error) {
        const errorMsg = getErrorMsg(error);
        yield put(actions.loadFavoriteCompaniesFailed(errorMsg));
    }
}

function* addCompanyToFavoriteSaga({ api }: IDependencies, action: NS.IAddCompanyToFavorite) {
    try {
        const companyID = action.payload;

        const result = yield call(api.companies.addCompanyToFavorite, companyID);
        yield put(actions.addCompanyToFavoriteCompleted(result));
    } catch (error) {
        const errorMsg = getErrorMsg(error);
        yield put(actions.addCompanyToFavoriteFailed(errorMsg));
    }
}

function* deleteCompanyFromFavoriteSaga({ api }: IDependencies, action: NS.IDeleteCompanyFromFavorite) {
    try {
        const favoriteID = action.payload;

        yield call(api.companies.deleteCompanyFromFavorite, favoriteID);
        yield put(actions.deleteCompanyFromFavoriteCompleted(favoriteID));
    } catch (error) {
        const errorMsg = getErrorMsg(error);
        yield put(actions.deleteCompanyFromFavoriteFailed(errorMsg));
    }
}

function* loadNoteFromServerSaga({ api }: IDependencies) {
    try {
        const notes = yield call(api.companies.loadNotes);
        yield put(actions.loadNoteFromServerCompleted(notes));
    } catch (error) {
        const errorMsg = getErrorMsg(error);
        yield put(actions.loadNoteFromServerFailed(errorMsg));
    }
}

function* saveNoteOnServerSaga({ api }: IDependencies, action: NS.ISaveNoteOnServer) {
    try {
        const result = yield call(api.companies.saveNotes, action.payload);
        yield put(actions.saveNoteOnServerCompleted(result));
    } catch (error) {
        const errorMsg = getErrorMsg(error);
        yield put(actions.saveNoteOnServerFailed(errorMsg));
    }
}

function* sendFeedbackSaga({ api }: IDependencies, action: NS.ISendFeedback) {
    try {
        yield call(api.companies.sendFeedback, action.payload);
        yield put(actions.sendFeedbackCompleted());
    } catch (error) {
        const errorMsg = getErrorMsg(error);
        yield put(actions.sendFeedbackFailed(errorMsg));
    }
}

function rootSaga(deps: IDependencies) {
    return function* saga(): SagaIterator {
        yield takeLatest(loadCompaniesType, loadCompaniesSaga, deps);
        yield takeLatest(sortCompaniesType, sortCompaniesSaga, deps);
        yield takeLatest(loadFavoriteCompaniesType, loadFavoriteCompaniesSaga, deps);
        yield takeLatest(addCompanyToFavoriteType, addCompanyToFavoriteSaga, deps);
        yield takeLatest(deleteCompanyFromFavoriteType, deleteCompanyFromFavoriteSaga, deps);
        yield takeLatest(loadNoteFromServerType, loadNoteFromServerSaga, deps);
        yield takeLatest(saveNoteOnServerType, saveNoteOnServerSaga, deps);
        yield takeLatest(sendFeedbackType, sendFeedbackSaga, deps);
        yield takeLatest(filterCompaniesType, filterCompaniesSaga, deps);
    };
}

export { rootSaga };
