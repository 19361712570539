import * as React from 'react';
import { block } from 'bem-cn';
import { autobind } from 'core-decorators';
import { Button, ButtonGroup, Colors, Sizes } from 'react-foundation';

import { INote } from '../../../types/companiesList';

import './Note.scss';

interface IProps {
    currentNoteText: string;
    companyID: number;

    onChange(note: string): void;

    onDiscard(): void;

    onSave(note: INote): void;
}

interface IState {
    isHidden: boolean;
    isChanged: boolean;
}

const b = block('note');

class Note extends React.Component<IProps, IState> {

    public state: IState = { isHidden: true, isChanged: false };

    public render(): React.ReactNode {
        const { currentNoteText } = this.props;
        const { isHidden } = this.state;
        return (
            <div className={b()}>
                <textarea
                    value={currentNoteText}
                    placeholder='Введите текст заметки'
                    onChange={this.handleTextareaChange}
                    onFocus={this.handleTextareaFocus}
                    // onBlur={this.handleTextareaBlur}
                />
                <div className={b('controls', { hide: isHidden })}>
                    <ButtonGroup size={Sizes.TINY}>
                        <Button onClick={this.handleSaveButton} color={Colors.SECONDARY}>Сохранить</Button>
                        <Button onClick={this.handleResetButton}>Отменить</Button>
                    </ButtonGroup>
                </div>
            </div>
        );
    }

    @autobind
    private handleTextareaFocus(): void {
        this.setState({ isHidden: false });
    }

    // @autobind
    // private handleTextareaBlur(): void {
    //     const {onSave, currentNoteText, companyID} = this.props;
    //     const {isChanged} = this.state;
    //     if (isChanged) {
    //         onSave({company: companyID, text: currentNoteText});
    //     }
    //     this.setState({isHidden: true});
    // }

    private handleTextareaChange = (event: React.FocusEvent<HTMLTextAreaElement>): void => {
        const { onChange } = this.props;
        this.setState({ isChanged: true });
        onChange(event.target.value);
    };

    @autobind
    private handleSaveButton(): void {
        const { onSave, currentNoteText, companyID } = this.props;
        const { isChanged } = this.state;
        if (isChanged) {
            onSave({ company: companyID, text: currentNoteText });
        }
        this.setState({ isHidden: true });
    }

    @autobind
    private handleResetButton(): void {
        const { onDiscard } = this.props;
        onDiscard();
        this.setState({ isHidden: true });
    }
}

export default Note;
