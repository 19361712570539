import { IAppReduxState } from '../../../shared/types/app';
import { ExcludingTabType, ICompany, INote } from '../../../shared/types/companiesList';

import * as NS from '../namespace';

export function selectState(state: IAppReduxState): NS.IReduxState {
    return state.companiesList;
}

export function selectCompaniesList(state: IAppReduxState): ICompany[] {
    return selectState(state).data.companiesList;
}

export function selectNextPage(state: IAppReduxState): string | null {
    return selectState(state).data.nextPage;
}

export function selectCursor(state: IAppReduxState): string {
    return selectState(state).data.cursor;
}

export function selectSortType(state: IAppReduxState): NS.SortType {
    return selectState(state).data.sortBy;
}

export function selectCompaniesCount(state: IAppReduxState): number {
    return selectState(state).data.companiesCount;
}

export function selectFavoriteCompanies(state: IAppReduxState): NS.IFavoriteCompany[] {
    return selectState(state).data.favoriteCompanies;
}

export function selectNoteList(state: IAppReduxState): INote[] {
    return selectState(state).data.noteList;
}

export function selectDefaultTab(state: IAppReduxState): ExcludingTabType {
    return selectState(state).data.defaultTab;
}

export function selectStatusFeedbackMessageSentOut(state: IAppReduxState): boolean {
    return selectState(state).data.isFeedbackMessageSentOut;
}

export function selectFeedbackMessageError(state: IAppReduxState): NS.ISendFeedbackMessageError | null {
    return selectState(state).data.sendFeedbackMessageError;
}
