import { makeCommunicationActionCreators } from 'redux-make-communication';
import * as NS from '../../namespace';

// tslint:disable:max-line-length
export const { execute: loadOrganizationsPoints, success: loadOrganizationsPointsCompleted, fail: loadOrganizationsPointsFailed } =
    makeCommunicationActionCreators<NS.ILoadOrganizationsPoints, NS.ILoadOrganizationsPointsCompleted, NS.ILoadOrganizationsPointsFailed>(
        'ORGANIZATIONS_MAP:LOAD_ORGANIZATIONS_POINTS',
        'ORGANIZATIONS_MAP:LOAD_ORGANIZATIONS_POINTS_COMPLETED',
        'ORGANIZATIONS_MAP:LOAD_ORGANIZATIONS_POINTS_FAILED'
    );
