import * as React from 'react';
import { block } from 'bem-cn';
import { autobind } from 'core-decorators';

import { INote } from '../../../../types/companiesList';

import TruncatingContainer from '../../TruncatingContainer/TruncatingContainer';
import Note from '../../Note/Note';
import { IContent } from '../Tabs';
import './Content.scss';

interface IProps {
    content: IContent;
    type: 'description' | 'contacts' | 'note';
    companyID: number;
    savedNote: string;

    onSaveNoteToServer(note: INote): void;
}

interface IState {
    currentNoteText: string;
}

const b = block('tab-content');

class Content extends React.Component<IProps, IState> {

    public state: IState = {
        currentNoteText: this.props.savedNote
    };

    public render(): React.ReactNode {
        const { type } = this.props;
        return (
            <div className={b({ type })}>
                {this.getActiveContent(type)}
            </div>
        );
    }

    private getActiveContent(type: IProps['type']): React.ReactNode {
        const { content: { description, address, phones }, companyID } = this.props;
        const { currentNoteText } = this.state;

        const renderDescription = (
            <TruncatingContainer lines={3}>
                <div className={b('description')}>
                    {description}
                </div>
            </TruncatingContainer>
        );

        const renderContacts = (
            <div className={b('contacts')}>
                <div className="hide">
                    <small className="color-light-black">Почтовый адрес:</small>
                    {` ${address}`}
                </div>
                {phones && phones.map(({ phoneNumber, comment }) => (
                    <div key={phoneNumber}>
                        <div className="color-light-black">
                            {`✆ ${phoneNumber} `}
                            <i className="gl">{comment}</i>
                        </div>
                    </div>
                ))}
            </div>
        );

        const renderNote = (
            <Note
                currentNoteText={currentNoteText}
                onChange={this.handleNoteChange}
                onDiscard={this.onDiscard}
                onSave={this.onSave}
                companyID={companyID}
            />
        );

        const contents: Record<IProps['type'], React.ReactNode> = {
            'description': renderDescription,
            'contacts': renderContacts,
            'note': renderNote
        };
        return contents[type];
    }

    private handleNoteChange = (note: string): void => {
        this.setState({ currentNoteText: note });
    };

    @autobind
    private onDiscard(): void {
        this.setState({ currentNoteText: this.props.savedNote });
    }

    @autobind
    private onSave(note: INote): void {
        const { onSaveNoteToServer } = this.props;
        onSaveNoteToServer(note);
    }
}

export default Content;
