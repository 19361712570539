import 'reflect-metadata';
import 'babel-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import configureApp from './core/configureApp';
import { App } from './core/App';

const appData = configureApp();

function render(component: React.ReactElement): void {
    ReactDOM.render(
        component,
        document.getElementById('companies')
    );
}

async function main(): Promise<void> {
    const app = <App {...appData} />;
    render(app);
}

/* Hot Module Replacement API */
if ((module as any).hot && !PRODUCTION) {
    (module as any).hot.accept(['./core/App', './core/configureApp'], () => {
        const nextConfigureApp: typeof configureApp = require('./core/configureApp').default;
        const NextApp: typeof App = require('./core/App').App;
        const nextAppData = nextConfigureApp(appData);
        render(<NextApp {...nextAppData} />);
    });
}

export { main };
