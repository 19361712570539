import { InfiniteScroll } from 'libreact/lib/InfiniteScroll';
import * as React from 'react';
import { block } from 'bem-cn';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { actions as userActions, selectors as userSelectors } from '../../../../../services/user';
import { CompaniesListHeaderType } from '../../../../../modules/shared/CompaniesListHeader/CompaniesListHeader';
import { ExcludingTabType , ICompany, INote } from '../../../../../shared/types/companiesList';
import { CompanyCard, Portal, Tabs } from '../../../../../shared/view/components';
import { IAppReduxState } from '../../../../../shared/types/app';

import { actions, selectors } from '../../../redux';
import * as NS from '../../../namespace';
import { COMPANIES_TABS, TYPES_OF_SORT } from '../../../constants';
import { Filters } from '../../../../../services/filters';
import './CompaniesList.scss';

interface IStateProps {
    companiesList: ICompany[];
    selectedSortType: NS.SortType;
    favoriteCompanies: NS.IFavoriteCompany[];
    isAuthorized: boolean;
    noteList: INote[];
    companiesCount: number;
    nextPage: string | null;
    cursor: string;
    defaultTab: ExcludingTabType;
    isFeedbackMessageSentOut: boolean;
    sendFeedbackMessageError: NS.ISendFeedbackMessageError | null;
}

interface IProps {
    header: CompaniesListHeaderType;
}

const mapDispatch = {
    loadCompaniesList: actions.loadCompanies,
    sortCompanies: actions.sortCompanies,
    setSortType: actions.setSortType,
    loadFavoriteCompanies: actions.loadFavoriteCompanies,
    getAuthorizedStatus: userActions.getAuthenticatedStatus,
    addCompanyToFavorite: actions.addCompanyToFavorite,
    deleteCompanyFromFavorite: actions.deleteCompanyFromFavorite,
    loadNoteFromServer: actions.loadNoteFromServer,
    onSaveNoteToServer: actions.saveNoteOnServer,
    sendFeedback: actions.sendFeedback,
    setDefaultTabs: actions.setDefaultTabs,
    setActiveTab: actions.setActiveTab,
    filterCompanies: actions.filterCompanies,
    resetFeedbackStatus: actions.resetFeedbackStatus,
    clearFeedbackMessageError: actions.clearFeedbackMessageError
};

type DispatchProps = typeof mapDispatch;

type Props = IStateProps & DispatchProps & IProps;

const b = block('companies-list');

// Needed in order to be built in a third-party block on the backend side
const filtersDomNode = document.getElementById('companies-filters');

class CompaniesList extends React.Component<Props> {

    public componentDidMount(): void {
        const { loadCompaniesList, getAuthorizedStatus } = this.props;
        loadCompaniesList();
        getAuthorizedStatus();
    }

    public componentDidUpdate(prevProps: Props): void {
        const { loadFavoriteCompanies, isAuthorized, loadNoteFromServer } = this.props;
        if (prevProps.isAuthorized !== isAuthorized && isAuthorized) {
            loadFavoriteCompanies();
            loadNoteFromServer();
        }
    }

    public render(): React.ReactNode {
        const {
            companiesList, selectedSortType, favoriteCompanies, isAuthorized, sendFeedback, header,
            setActiveTab, companiesCount, noteList, onSaveNoteToServer, isFeedbackMessageSentOut,
            resetFeedbackStatus, sendFeedbackMessageError, clearFeedbackMessageError,
            loadCompaniesList, nextPage, cursor
        } = this.props;
        const CompaniesListHeader = header;
        const hasMore = nextPage !== null;

        return (
            <div className={b()}>
                <div className={b('header')}>
                    <CompaniesListHeader
                        sortTypes={TYPES_OF_SORT}
                        onSortTypeSelect={this.handleSortTypeSelect}
                        selectedSortType={selectedSortType}
                        onIconButtonClick={this.handleCurrentTabChange}
                        isAuthorized={isAuthorized}
                        companiesCount={companiesCount}
                    />
                </div>
                <div className={b('content')}>
                    <div className={b('list')}>
                        <InfiniteScroll hasMore={hasMore} cursor={cursor} loadMore={loadCompaniesList}>
                            {companiesList.map((company) => {
                                const isFavorite = favoriteCompanies.filter(favorite => favorite.companyID === company.id).length !== 0;
                                const savedNote: INote = noteList && noteList.filter((item) => item.company === company.id)[0];
                                const { description, address, contacts } = company;
                                return (
                                    <div className={b('company')} key={company.id}>
                                        <CompanyCard
                                            companyInfo={company}
                                            onFavoriteSelect={this.handleFavoriteClick}
                                            isFavorite={isFavorite}
                                            isAuthorized={isAuthorized}
                                        >
                                            <Tabs
                                                tabsContent={{ description, address, phones: contacts }}
                                                tabs={COMPANIES_TABS}
                                                defaultTab={company.activeTabs || this.props.defaultTab}
                                                onSetActiveTab={setActiveTab}
                                                isAuthorized={isAuthorized}
                                                company={company}
                                                sendFeedback={sendFeedback}
                                                companyID={company.id}
                                                savedNote={savedNote && savedNote.text.length > 0 ? savedNote.text : ''}
                                                onSaveNoteToServer={onSaveNoteToServer}
                                                isFeedbackMessageSentOut={isFeedbackMessageSentOut}
                                                resetFeedbackStatus={resetFeedbackStatus}
                                                sendFeedbackMessageError={sendFeedbackMessageError}
                                                clearFeedbackMessageError={clearFeedbackMessageError}
                                            />
                                        </CompanyCard>
                                    </div>
                                );
                            })}
                        </InfiniteScroll>
                    </div>
                    {filtersDomNode &&
                    <Portal parent={filtersDomNode}>
                        <div className={b('filters')}>
                            <Filters onFiltersClick={this.handleFiltersClick}/>
                        </div>
                    </Portal>
                    }
                </div>
            </div>
        );
    }

    @autobind
    private handleFavoriteClick(isFavorite: boolean, companyID: number): void {
        const { addCompanyToFavorite, deleteCompanyFromFavorite, favoriteCompanies } = this.props;

        if (isFavorite) {
            const currentFavoriteCompany = favoriteCompanies.filter(favorite => favorite.companyID === companyID);
            const favoriteID = currentFavoriteCompany[0].favoriteID;
            deleteCompanyFromFavorite(favoriteID);
        } else {
            addCompanyToFavorite(companyID);
        }
    }

    @autobind
    private handleCurrentTabChange(type: ExcludingTabType): void {
        const { setDefaultTabs } = this.props;
        setDefaultTabs(type);
    }

    @autobind
    private handleSortTypeSelect(type: NS.SortType): void {
        const { setSortType, sortCompanies } = this.props;
        setSortType(type);
        sortCompanies();
    }

    @autobind
    private handleFiltersClick(): void {
        const { filterCompanies } = this.props;
        filterCompanies();
    }
}

function mapState(state: IAppReduxState): IStateProps {
    return {
        companiesList: selectors.selectCompaniesList(state),
        selectedSortType: selectors.selectSortType(state),
        favoriteCompanies: selectors.selectFavoriteCompanies(state),
        isAuthorized: userSelectors.selectAuthenticatedStatus(state),
        noteList: selectors.selectNoteList(state),
        companiesCount: selectors.selectCompaniesCount(state),
        nextPage: selectors.selectNextPage(state),
        cursor: selectors.selectCursor(state),
        defaultTab: selectors.selectDefaultTab(state),
        isFeedbackMessageSentOut: selectors.selectStatusFeedbackMessageSentOut(state),
        sendFeedbackMessageError: selectors.selectFeedbackMessageError(state)
    };
}

export default connect<IStateProps, DispatchProps, {}>(mapState, mapDispatch)(CompaniesList);
