import * as React from 'react';
import { block } from 'bem-cn';
import { autobind } from 'core-decorators';

import { FilterListItem } from '../FilterListItem/FilterListItem';
import * as NS from '../../../../services/filters/namespace';

import './DropDown.scss';

interface IProps {
    filter: NS.IFilter;
    type: NS.FilterType;

    onFilterItemClick(id: number, eventTarget: string, filterType: NS.FilterType): void;
}

interface IState {
    isDropDownOpen: boolean;
    isAllItemsOpen: boolean;
}

const b = block('drop-down');

const defaultNumberOfShownUnits = 5;
const maxNumberOfShownUnits = 1000;

class DropDown extends React.Component<IProps, IState> {

    public state: IState = {
        isDropDownOpen: true,
        isAllItemsOpen: false
    };

    public render(): React.ReactNode {
        const { filter, type, onFilterItemClick } = this.props;
        const { isDropDownOpen, isAllItemsOpen } = this.state;
        const shortList: boolean = type === 'geo' || type === 'brand';
        return (
            <div className={b()}>
                <div className={b('select')} onClick={this.handleSelectClick}>
                    <div className={b('arrow', { 'to-bottom ': isDropDownOpen })}/>
                    {filter.title}
                </div>
                <div className={b('options', { 'hidden': !isDropDownOpen })}>
                    <div className={b('content')}>
                        {filter.values && filter.values.map((item, index) => {
                            const numberOfShownUnits = isAllItemsOpen ? maxNumberOfShownUnits : defaultNumberOfShownUnits;
                            return (
                                <FilterListItem
                                    item={item}
                                    onFilterItemClick={onFilterItemClick}
                                    invisible={shortList && index > numberOfShownUnits}
                                    filterType={type}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                    {shortList && !isAllItemsOpen &&
                    <div className={b('button-wrapper')}>
                        <button className={b('show-all-button')} onClick={this.handleShowAllButtonClick}>
                            {'Показать всё'}
                        </button>
                    </div>
                    }
                </div>
            </div>
        );
    }

    @autobind
    private handleSelectClick(): void {
        this.setState(prevState => ({ isDropDownOpen: !prevState.isDropDownOpen }));
    }

    @autobind
    private handleShowAllButtonClick(): void {
        this.setState({ isAllItemsOpen: true });
    }
}

export default DropDown;
