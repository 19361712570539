import * as React from 'react';
import { block } from 'bem-cn';

import { ExcludingTabType } from '../../../../shared/types/companiesList';
import { Checkbox } from '../../../../shared/view/elements';

import IconButton from '../IconButton/IconButton';
import './ButtonsPanel.scss';

const b = block('buttons-panel');

interface IProps {
    isAuthorized: boolean;

    onChange(): void;

    onButtonClick(type: ExcludingTabType): void;
}

class ButtonsPanel extends React.PureComponent<IProps> {

    public render(): React.ReactNode {
        const iconButtonsOrder: ExcludingTabType[] = ['description', 'contacts', 'note'];
        const { onChange, onButtonClick, isAuthorized } = this.props;

        const iconButtonsOrderForGuest = iconButtonsOrder.filter((item) => item !== 'note');

        return (
            <div className={b()}>
                <div className={b('wrapper')}>
                    <Checkbox name="map-checkbox" value="0" label="Показать карту" onChange={onChange}/>
                </div>
                {
                    (isAuthorized ? iconButtonsOrder : iconButtonsOrderForGuest)
                        .map((iconButtonType, index) => (
                            <div key={index} className={b('wrapper')}>
                                <IconButton type={iconButtonType} onButtonClick={onButtonClick}/>
                            </div>
                        ))
                }
            </div>
        );
    }
}

export default ButtonsPanel;
