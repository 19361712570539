import * as React from 'react';
import { block } from 'bem-cn';
import InlineSVG from 'svg-inline-react';
import { autobind } from 'core-decorators';

import CompanyStatusIcon from '../../../../shared/view/components/CompanyStatusIcon/CompanyStatusIcon';
import { SubscribeType } from '../../../types/models';

import dateIcon from './svg/favorite-source.svg';
import './CompanyCard.scss';

interface ICompany {
    logo: string;
    name: string;
    publicationDate: string | null;
    subscribeType: SubscribeType;
    isBest: boolean;
    url: string;
    id: number;
}

interface IProps {
    companyInfo: ICompany;
    isFavorite: boolean;
    isAuthorized: boolean;

    onFavoriteSelect(isFavorite: boolean, id: number): void;
}

const b = block('company-card');

class CompanyCard extends React.PureComponent<React.PropsWithChildren<IProps>> {

    public render(): React.ReactNode {
        const {
            companyInfo: { logo, name, subscribeType, publicationDate, url, isBest },
            isFavorite,
            isAuthorized,
            children
        } = this.props;

        return (
            <section className={b()}>
                {
                    logo
                        ? <img className={b('logo')} src={logo} alt={name}/>
                        : <div className={b('logo').state({ empty: true })}/>
                }
                <div className={b('content')}>
                    <div className={b('header')}>
                        <h3 className={b('name')}>
                            <a href={url} className={b('link')} target="_blank" rel="noopener">{name}</a>
                        </h3>
                        {subscribeType &&
                        <div className={b('status')}>
                            <CompanyStatusIcon status={subscribeType} publicationDate={publicationDate} companyName={name}/>
                        </div>}
                    </div>
                    {children}
                </div>
                {isAuthorized &&
                <InlineSVG
                    className={b('favorite', { chosen: isFavorite })}
                    component="div"
                    src={dateIcon}
                    onClick={this.handleInlineSVGClick}
                />
                }
                {isBest && <div className={b('recomend')}/>}
            </section>
        );
    }

    @autobind
    private handleInlineSVGClick(): void {
        const { isFavorite, companyInfo: { id }, onFavoriteSelect } = this.props;
        onFavoriteSelect(isFavorite, id);
    }
}

export { ICompany };
export default CompanyCard;
