function convertCamelToSnakeCase(str: string): string {

    function upperToSnakeLower(match: string): string {
        return '_' + match.toLowerCase();
    }

    return str.replace(/[A-Z]/g, upperToSnakeLower);
}

export default convertCamelToSnakeCase;
