import * as React from 'react';
import { block } from 'bem-cn';
import { autobind } from 'core-decorators';

import { ExcludingTabType } from '../../../shared/types/companiesList';
import { SortType } from '../../../features/companiesList/namespace';
import { OrganizationsMap } from '../../../features/organizationsMap';

import SortControlsPanel from './SortControlsPanel/SortControlsPanel';
import ButtonsPanel from './ButtonsPanel/ButtonsPanel';
import './CompaniesListHeader.scss';

const b = block('catalog-header');

interface IProps {
    sortTypes: ISortType[];
    selectedSortType: SortType;
    isAuthorized: boolean;
    companiesCount: number;

    onIconButtonClick(type: ExcludingTabType): void;

    onSortTypeSelect(type: SortType): void;
}

interface ISortType {
    type: string;
    title: string;
}

interface IState {
    isMapVisible: boolean;
}

class CompaniesListHeader extends React.Component<IProps, IState> {

    public state: IState = { isMapVisible: false };

    public render(): React.ReactNode {
        const {
            sortTypes, onSortTypeSelect, selectedSortType, onIconButtonClick, isAuthorized
        } = this.props;

        return (
            <section className={b()}>
                <div className={b('controls')}>
                    <div className={b('sort-controls-panel')}>
                        <SortControlsPanel
                            sortTypes={sortTypes}
                            onSortTypeSelect={onSortTypeSelect}
                            selectedSortType={selectedSortType}
                        />
                    </div>
                    <div className={b('buttons-panel')}>
                        <ButtonsPanel
                            onChange={this.toggleMapVisibility}
                            onButtonClick={onIconButtonClick}
                            isAuthorized={isAuthorized}
                        />
                    </div>
                </div>
                <div className={b('map', { visible: this.state.isMapVisible })}>
                    <OrganizationsMap mapVisibility={this.state.isMapVisible}/>
                </div>
            </section>
        );
    }

    @autobind
    private toggleMapVisibility(): void {
        this.setState(prevState => ({ isMapVisible: !prevState.isMapVisible }));
    }
}

export default CompaniesListHeader;
export type CompaniesListHeaderType = typeof CompaniesListHeader;
