import * as NS from '../../namespace';
import { initial } from '../initial';

function insertChildren(id: number, values: NS.IFilterValue[] | undefined, valuesStructure: NS.IFilterValue[] | undefined): NS.IFilterValue[] {
    if (valuesStructure) {
        return valuesStructure.map(value => {
            if (value.id === id) {
                const children = values?.map(item => {
                    return { ...item, checked: false, disabled: false };
                });
                return { ...value, children };
            } else if (value.children) {
                return { ...value, children: insertChildren(id, values, value.children) };
            }
            return { ...value };
        });
    }
    return [];
}

function toggleChildrenVisibility(id: number, valuesStructure: NS.IFilterValue[] | undefined): NS.IFilterValue[] {
    if (valuesStructure) {
        return valuesStructure.map(value => {
            if (value.id === id) {
                return { ...value, childrenVisibility: !value.childrenVisibility };
            } else if (value.children) {
                return { ...value, children: toggleChildrenVisibility(id, value.children) };
            }
            return { ...value };
        });
    }
    return [];
}

function toggleCheckbox(id: number, valuesStructure: NS.IFilterValue[] | undefined): NS.IFilterValue[] {
    if (valuesStructure) {
        return valuesStructure.map(value => {
            if (value.id === id) {
                return { ...value, checked: !value.checked };
            } else if (value.children) {
                return { ...value, children: toggleCheckbox(id, value.children) };
            }
            return { ...value };
        });
    }
    return [];
}

function resetAllCheckboxes(filter: NS.IFilterValue[] | undefined): NS.IFilterValue[] {
    if (filter) {
        return filter.map(value => {
            if (value.children) {
                return { ...value, checked: false, disabled: false, children: resetAllCheckboxes(value.children) };
            }
            return { ...value, checked: false, disabled: false };
        });
    }
    return [];
}

function resetInsideCheckboxes(id: number, valuesStructure: NS.IFilterValue[] | undefined): NS.IFilterValue[] {
    if (valuesStructure) {
        return valuesStructure.map(value => {
            if (value.id === id && value.children) {
                return { ...value, children: resetAllCheckboxes(value.children) };
            } else if (value.children) {
                return { ...value, children: resetInsideCheckboxes(id, value.children) };
            }
            return { ...value };
        });
    }
    return [];
}

function findAllChecked(valuesStructure: NS.IFilterValue[]): number[] {
    return valuesStructure.reduce((acc, value) => {
        if (value.children) {
            if (value.checked) {
                return [...acc, value.id, ...findAllChecked(value.children)];
            }
            return [...acc, ...findAllChecked(value.children)];
        } else if (value.checked) {
            return [...acc, value.id];
        }
        return acc;
    }, []);
}

function findCheckedChildren(id: number, valuesStructure: NS.IFilterValue[] | undefined): number[] {
    if (valuesStructure) {
        return valuesStructure.reduce((acc, value) => {
            if (value.id === id && value.children) {
                return [...acc, ...findAllChecked(value.children)];
            } else if (value.children) {
                return findCheckedChildren(id, value.children);
            }
            return acc;
        }, []);
    }
    return [];
}

function mergeFilterValues(oldFilters: NS.IFilterValue[], sentFilters: NS.IFilterValue[]): NS.IFilterValue[] {
    return oldFilters.map(value => {
        const isIDinSentFiltersExist = sentFilters.some(item => value.id === item.id);
        if (isIDinSentFiltersExist) {
            return { ...value, disabled: false };
        } else {
            return { ...value, disabled: true };
        }
    });
}

export function dataReducer(state: NS.IReduxState['data'] = initial.data, action: NS.Action): {
    filters: NS.IFilters | null;
    selectedFilters: NS.ISelectedFilters;
    sectionParentId: number;
} {
    switch (action.type) {
    case 'FILTERS:LOAD_FILTERS_COMPLETED': {
        const filters = action.payload;
        const newFilters = Object.keys(filters).reduce((acc: NS.IFilters, filter) => {
            if (filters[filter].values?.length === 0) {
                acc[filter] = { ...filters[filter], checked: false, disabled: false };
            } else {
                const newValues = filters[filter].values?.map(value => {
                    return { ...value, checked: false, childrenVisibility: false, disabled: false };
                });
                acc[filter] = { ...filters[filter], values: newValues };
            }
            return acc;
        }, {});

        return {
            ...state,
            filters: newFilters
        };
    }

    case 'FILTERS:LOAD_DEPENDENT_FILTERS_COMPLETED': {
        const oldFilters = state.filters ? state.filters : {};
        const sentFilters = action.payload ? action.payload : {};
        const newFilters = Object.keys(oldFilters).reduce((acc: NS.IFilters, filter) => {
            const oldValues = oldFilters[filter].values;
            const oldFiltersValues = oldValues ? oldValues : [];
            const sentValues = sentFilters[filter].values;
            const sentFiltersValues = sentValues ? sentValues : [];
            if (oldFilters[filter].values?.length === 0) {
                acc[filter] = oldFilters[filter].title === sentFilters[filter].title
                    ? { ...oldFilters[filter] }
                    : { ...oldFilters[filter], disabled: true };
            } else {
                acc[filter] = { ...oldFilters[filter], values: mergeFilterValues(oldFiltersValues, sentFiltersValues) };
            }
            return acc;
        }, {});

        return {
            ...state,
            filters: newFilters
        };
    }

    case 'FILTERS:LOAD_INSIDE_FILTERS_COMPLETED': {
        if (!state.filters) {
            return state;
        }
        const { values, id, filterType } = action.payload;
        const valuesStructure = state.filters[filterType].values;
        const newValues = insertChildren(id, values, valuesStructure);

        return {
            ...state,
            filters: { ...state.filters, [filterType]: { ...state.filters[filterType], values: newValues }}
        };
    }

    case 'FILTERS:OPEN_INSIDE_FILTERS': {
        if (!state.filters) {
            return state;
        }
        const { id, filterType } = action.payload;
        const valuesStructure = state.filters[filterType].values;
        const newValues = toggleChildrenVisibility(id, valuesStructure);
        return {
            ...state,
            filters: { ...state.filters, [filterType]: { ...state.filters[filterType], values: newValues }}
        };
    }

    case 'FILTERS:TOGGLE_FILTERS_CHECKS': {
        if (!state.filters) {
            return state;
        }
        const { id, filterType } = action.payload;
        const valuesStructure = state.filters[filterType].values;
        const newValues = toggleCheckbox(id, valuesStructure);
        const selectedFilter = state.selectedFilters[filterType] ? state.selectedFilters[filterType] : [];
        const isIDExistInSelectedFilters = selectedFilter.filter(itemID => id === itemID).length !== 0;
        const newSelectedFilters = isIDExistInSelectedFilters
            ? {
                ...state.selectedFilters, [filterType]: [...selectedFilter.filter(itemID => id !== itemID)]
            }
            : { ...state.selectedFilters, [filterType]: [...selectedFilter, id]};
        if (id === 1 && (filterType === 'isBest' || filterType === 'onlineShop')) {
            return {
                ...state,
                selectedFilters: newSelectedFilters,
                filters: {
                    ...state.filters,
                    [filterType]: { ...state.filters[filterType], checked: !state.filters[filterType].checked }
                }
            };
        }
        return {
            ...state,
            selectedFilters: newSelectedFilters,
            filters: { ...state.filters, [filterType]: { ...state.filters[filterType], values: newValues }}
        };
    }

    case 'FILTERS:RESET_FILTERS_CHECKS': {
        const filters = state.filters ? state.filters : {};
        const newFilters = Object.keys(filters).reduce((acc: NS.IFilters, filter) => {
            filters[filter].type === 'checkbox'
                ? acc[filter] = { ...filters[filter], checked: false }
                : acc[filter] = { ...filters[filter], values: resetAllCheckboxes(filters[filter].values) };
            return acc;
        }, {});
        return {
            ...state,
            selectedFilters: {},
            filters: newFilters
        };
    }

    case 'FILTERS:RESET_INSIDE_FILTERS_CHECKS': {
        if (!state.filters) {
            return state;
        }
        const { id, filterType } = action.payload;
        const valuesStructure = state.filters[filterType].values;
        const newValues = resetInsideCheckboxes(id, valuesStructure);
        const checkedChildren = findCheckedChildren(id, valuesStructure);
        const newChildren = state.selectedFilters[filterType].filter(item => !checkedChildren.some(el => el === item));
        const newSelectedFilters = { ...state.selectedFilters, [filterType]: newChildren };

        return {
            ...state,
            selectedFilters: newSelectedFilters,
            filters: { ...state.filters, [filterType]: { ...state.filters[filterType], values: newValues }}
        };
    }

    case 'FILTERS:GET_SECTION_PARENT_ID': {
        return {
            ...state,
            sectionParentId: action.payload
        };
    }

    default:
        return state;
    }
}
