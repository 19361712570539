import * as NS from '../../namespace';

export function openInsideFilters(payload: NS.IInsideFilterPayload): NS.IOpenInsideFilters {
    return { type: 'FILTERS:OPEN_INSIDE_FILTERS', payload };
}

export function toggleFiltersChecks(payload: NS.IInsideFilterPayload): NS.IToggleFiltersChecks {
    return { type: 'FILTERS:TOGGLE_FILTERS_CHECKS', payload };
}

export function resetFiltersChecks(): NS.IResetFiltersChecks {
    return { type: 'FILTERS:RESET_FILTERS_CHECKS' };
}

export function resetInsideFiltersChecks(payload: NS.IInsideFilterPayload): NS.IResetInsideFiltersChecks {
    return { type: 'FILTERS:RESET_INSIDE_FILTERS_CHECKS', payload };
}

export function getSectionParentId() {
    const parentId = window.parentId;
    return { type: 'FILTERS:GET_SECTION_PARENT_ID', payload: parentId };
}
