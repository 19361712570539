import * as React from 'react';
import { block } from 'bem-cn';
import { autobind } from 'core-decorators';
import { Button, Colors } from 'react-foundation';

import * as NS from '../../../../services/filters/namespace';
import { Checkbox } from '../../../../shared/view/elements';

import DropDown from '../DropDown/DropDown';
import './ExtraFilters.scss';

interface IProps {
    filters: NS.IFilters;

    onResetButtonClick(): void;

    onFilterItemClick(id: number, eventTarget: string, filterType: NS.FilterType): void;
}

const b = block('extra-filters');

class ExtraFilters extends React.Component<IProps> {

    public render(): React.ReactNode {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { filters: { rubrica, ...filters }, onFilterItemClick } = this.props;

        return (
            <div className={b()}>
                {
                    Object.keys(filters).map((type: NS.FilterType, index) => {
                        return filters[type].type !== 'checkbox'
                            && (
                                <div key={index} className={b('drop-down')}>
                                    <DropDown filter={filters[type]} type={type} onFilterItemClick={onFilterItemClick}/>
                                </div>
                            );
                    })
                }
                <div className={b('line')}/>
                {
                    Object.keys(filters).map(filter => {
                        return filters[filter].type === 'checkbox'
                            && (
                                <div key={filter} className={b('checkbox')}>
                                    <Checkbox
                                        name="checkbox"
                                        value="checkbox"
                                        checked={filters[filter].checked}
                                        disabled={filters[filter].disabled}
                                        label={filters[filter].title}
                                        onChange={onFilterItemClick.bind(this, 1, 'checkbox', filter)}
                                    />
                                </div>
                            );
                    })
                }

                <div className={b('reset-button-wrapper')}>
                    <Button
                        color={Colors.PRIMARY}
                        isExpanded={true}
                        isHollow={true}
                        onClick={this.handleResetButtonClick}
                    >
                        Сбросить
                    </Button>
                </div>
            </div>
        );
    }

    @autobind
    private handleResetButtonClick(): void {
        const { onResetButtonClick } = this.props;
        onResetButtonClick();
    }
}

export default ExtraFilters;
