import { ITab } from '../../shared/view/components/Tabs/Tabs';

export const COMPANIES_TABS: ITab[] = [
    { type: 'description', title: 'Описание компании' },
    { type: 'contacts', title: 'Контакты' },
    { type: 'note', title: 'Заметка' },
    { type: 'sendMessage', title: 'Отправить сообщение' }
];

export const TYPES_OF_SORT = [
    { type: 'order', title: 'по рейтингу' },
    { type: 'regDate', title: 'по дате основания' },
    { type: 'title', title: 'по названию' }
];
