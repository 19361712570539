import { autobind } from 'core-decorators';

import { IFormattedBoundsCoords } from '../../../shared/view/components/YandexMap/YandexMap';
import { ICompany } from '../../../features/organizationsMap/namespace';
import { ISelectedFilters } from '../../filters/namespace';

import { convertOrganizationsMapRequestToRequestString, convertServerOrganizationsMap } from '../converters';
import { IServerCompanyForMap } from '../types';
import BaseApi from './BaseApi';

class OrganizationsMap extends BaseApi {

    @autobind
    public async getOrganizationsPoints(
        boundsCoords: IFormattedBoundsCoords,
        filters: ISelectedFilters,
        sectionParentId: number
    ): Promise<ICompany[]> {
        const requestParameters: string = convertOrganizationsMapRequestToRequestString(
            boundsCoords, filters, sectionParentId
        );
        const response = await this.actions.get<IServerCompanyForMap[]>(
            `/catalog/map/?${requestParameters}`
        );
        return this.handleResponse(response, convertServerOrganizationsMap);
    }
}

export default OrganizationsMap;
