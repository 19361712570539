import * as React from 'react';
import { block } from 'bem-cn';
import InlineSVG from 'svg-inline-react';
import { autobind } from 'core-decorators';

import { ExcludingTabType } from '../../../../shared/types/companiesList';
import { Button } from '../../../../shared/view/elements';

import defaultIcon from './svg/description-source.svg';
import contactsIcon from './svg/contacts-source.svg';
import noteIcon from './svg/note-source.svg';
import './IconButton.scss';

const b = block('icon-button');

const icons: Record<string, () => string> = {
    'description': () => defaultIcon,
    'contacts': () => contactsIcon,
    'note': () => noteIcon
};

interface IProps {
    type: ExcludingTabType;

    onButtonClick(type: ExcludingTabType): void;
}

class IconButton extends React.PureComponent<IProps> {

    public render(): React.ReactNode {
        const { type } = this.props;

        return (
            <div className={b()}>
                <Button onClick={this.handleButtonClick}>
                    <InlineSVG
                        className={b('image', { type })}
                        element="div"
                        src={icons[type]()}
                    />
                </Button>
            </div>
        );
    }

    @autobind
    private handleButtonClick(): void {
        const { onButtonClick, type } = this.props;
        if (onButtonClick) {
            onButtonClick(type);
        }
    }
}

export default IconButton;
