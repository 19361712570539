import * as React from 'react';
import { block } from 'bem-cn';
import { autobind } from 'core-decorators';

import { SortType } from '../../../../features/companiesList/namespace';
import { Button } from '../../../../shared/view/elements';

import './SortControlsPanel.scss';

const b = block('sort-controls-panel');

interface IProps {
    sortTypes: ISortType[];
    selectedSortType: SortType;

    onSortTypeSelect(type: SortType): void;
}

interface ISortType {
    type: string;
    title: string;
}

class SortControlsPanel extends React.PureComponent<IProps> {

    public render(): React.ReactNode {
        const { sortTypes, selectedSortType } = this.props;

        return (
            <div className={b()}>
                <div className={b('item')}>
                    Сортировать:
                </div>
                {sortTypes.map((sortType, index) => {
                    return (
                        <div className={b('item')} key={index}>
                            <Button
                                active={selectedSortType === sortType.type}
                                onClick={this.makeSortTypeClickHandler(sortType.type as SortType)}
                            >
                                {sortType.title}
                            </Button>
                        </div>
                    );
                })}
            </div>
        );
    }

    @autobind
    private makeSortTypeClickHandler(type: SortType) {
        const { onSortTypeSelect } = this.props;
        return () => onSortTypeSelect(type);
    }
}

export default SortControlsPanel;
