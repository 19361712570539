import * as React from 'react';

class App extends React.Component<React.PropsWithChildren<{}>> {

    public render(): React.ReactNode {
        const { children } = this.props;

        return children;
    }
}

export default App;
