import * as React from 'react';
import { block } from 'bem-cn';
import { autobind } from 'core-decorators';

import { FilterListItem } from '../FilterListItem/FilterListItem';
import { FilterType, IFilterValue } from '../../../../services/filters/namespace';

import './SectionFilters.scss';

const b = block('section-filter');

interface IProps {
    itemsList: IFilterValue[];
    headerText: string;

    onFilterItemClick(id: number, eventTarget: string, filterType: FilterType): void;
}

interface IState {
    isAllItemsOpen: boolean;
}

const defaultNumberOfShownUnits = 15;
const maxNumberOfShownUnits = 1000;

class SectionFilter extends React.Component<IProps, IState> {
    public state = { isAllItemsOpen: false };

    public render(): React.ReactNode {
        const { itemsList, headerText, onFilterItemClick } = this.props;
        const { isAllItemsOpen } = this.state;

        return (
            <div className={b()}>
                <div className={b('header')}>
                    {headerText}
                </div>
                <div className={b('content')}>
                    {itemsList.map((item, index) => {
                        const numberOfShownUnits = isAllItemsOpen ? maxNumberOfShownUnits : defaultNumberOfShownUnits;
                        return (
                            <FilterListItem
                                item={item}
                                invisible={index > numberOfShownUnits}
                                filterType={'rubrica'}
                                onFilterItemClick={onFilterItemClick}
                                key={index}
                            />
                        );
                    })}
                </div>
                {!isAllItemsOpen &&
                <div className={b('button-wrapper')}>
                    <button className={b('show-all-button')} onClick={this.handleShowAllButtonClick}>
                        {'Показать всё'}
                    </button>
                </div>
                }
            </div>
        );
    }

    @autobind
    private handleShowAllButtonClick(): void {
        this.setState({ isAllItemsOpen: true });
    }
}

export default SectionFilter;
