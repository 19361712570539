import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { IDependencies } from '../../../../shared/types/app';
import { getErrorMsg } from '../../../../shared/helpers';

import { selectSectionParentId, selectSelectedFilters } from '../selectors';
import * as actions from '../actions';
import * as NS from '../../namespace';

const loadFiltersType: NS.ILoadFilters['type'] = 'FILTERS:LOAD_FILTERS';
const loadDependentFiltersType: NS.ILoadDependentFilters['type'] = 'FILTERS:LOAD_DEPENDENT_FILTERS';
const loadInsideFiltersType: NS.ILoadInsideFilters['type'] = 'FILTERS:LOAD_INSIDE_FILTERS';

function* loadFiltersSaga({ api }: IDependencies, action: NS.ILoadFilters) {
    try {
        const filters = yield select(selectSelectedFilters);
        const sectionParentId = action.payload;
        const companies = yield call(api.filters.loadFilters, filters, sectionParentId);
        yield put(actions.loadFiltersCompleted(companies));
    } catch (error) {
        const errorMsg = getErrorMsg(error);
        yield put(actions.loadFiltersFailed(errorMsg));
    }
}

function* loadDependentFiltersSaga({ api }: IDependencies, action: NS.ILoadFilters) {
    try {
        const filters = yield select(selectSelectedFilters);
        const sectionParentId = action.payload;
        const companies = yield call(api.filters.loadDependentFilters, filters, sectionParentId);
        yield put(actions.loadDependentFiltersCompleted(companies));
    } catch (error) {
        const errorMsg = getErrorMsg(error);
        yield put(actions.loadDependentFiltersFailed(errorMsg));
    }
}

function* loadInsideFiltersSaga({ api }: IDependencies, action: NS.ILoadInsideFilters) {
    try {
        const { id, filterType } = action.payload;
        const sectionParentId = yield select(selectSectionParentId);
        const insideFilters = yield call(api.filters.loadInsideFilters, id, filterType, sectionParentId);
        yield put(actions.loadInsideFiltersCompleted({ values: insideFilters, id, filterType }));
    } catch (error) {
        const errorMsg = getErrorMsg(error);
        yield put(actions.loadInsideFiltersFailed(errorMsg));
    }
}

function rootSaga(deps: IDependencies) {
    return function* saga(): SagaIterator {
        yield takeLatest(loadFiltersType, loadFiltersSaga, deps);
        yield takeLatest(loadDependentFiltersType, loadDependentFiltersSaga, deps);
        yield takeLatest(loadInsideFiltersType, loadInsideFiltersSaga, deps);
    };
}

export { rootSaga };
