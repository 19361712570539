import * as React from 'react';
import { block } from 'bem-cn';

import './Button.scss';

const b = block('sort-button');

interface IProps {
    onClick: () => void;
    active?: boolean;
}

class Button extends React.PureComponent<React.PropsWithChildren<IProps>> {

    public render(): React.ReactNode {
        const { onClick, children, active } = this.props;

        return (
            <a className={b({ active })} onClick={onClick}>
                {children}
            </a>
        );
    }
}

export default Button;
