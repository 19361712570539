import * as NS from '../../namespace';
import { initial } from '../initial';

export function dataReducer(state: NS.IReduxState['data'] = initial.data, action: NS.Action) {
    switch (action.type) {
    case 'ORGANIZATIONS_MAP:LOAD_ORGANIZATIONS_POINTS_COMPLETED': {
        return {
            ...state,
            organizationsPoints: action.payload
        };
    }
    default:
        return state;
    }
}
