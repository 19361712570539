import { autobind } from 'core-decorators';
import { ICompaniesInfo, SortType } from '../../../features/companiesList/namespace';
import { IFeedback, INote } from '../../../shared/types/companiesList';
import { ISelectedFilters } from '../../filters/namespace';

import {
    convertClientToServerFeedback,
    convertDataToRequestString,
    convertServerAddFavorite,
    convertServerCompaniesList,
    convertServerFavorite
} from '../converters';
import { IFavoriteCompany, IServerCompaniesList, IServerFavoriteCompany } from '../types';
import BaseApi from './BaseApi';

class CompaniesList extends BaseApi {

    @autobind
    public async loadCompanies(nextPage: string | null, sortBy: SortType, filters: ISelectedFilters): Promise<ICompaniesInfo> {
        const requestParameters: string = convertDataToRequestString(nextPage, sortBy, filters);
        const response = await this.actions.get<IServerCompaniesList>(`/catalog/company/?${requestParameters}`);
        return this.handleResponse(response, convertServerCompaniesList);
    }

    @autobind
    public async loadFavoriteCompanies(): Promise<IFavoriteCompany[]> {
        const response = await this.actions.get<IServerFavoriteCompany[]>('/catalog/favorites/');
        return this.handleResponse(response, convertServerFavorite);
    }

    @autobind
    public async addCompanyToFavorite(companyID: number): Promise<IFavoriteCompany> {
        const data = { 'object_id': companyID };
        const response = await this.actions.post<IServerFavoriteCompany>('/catalog/favorites/', data);
        return this.handleResponse(response, convertServerAddFavorite);
    }

    @autobind
    public async deleteCompanyFromFavorite(favoriteID: number): Promise<any> {
        return this.actions.delete<any>(`/catalog/favorites/${favoriteID}/`);
    }

    @autobind
    public async saveNotes(notes: INote): Promise<INote> {
        const response = await this.actions.post<INote>('/catalog/memo/', notes);
        return this.handleResponse(response, data => data);
    }

    @autobind
    public async loadNotes(): Promise<INote[]> {
        const response = await this.actions.get<INote[]>('/catalog/memo/');
        return this.handleResponse(response, data => data);
    }

    @autobind
    public async sendFeedback(feedback: IFeedback): Promise<IFeedback> {
        const response = await this.actions.post<IFeedback>(
            '/catalog/feedback/',
            convertClientToServerFeedback(feedback)
        );
        return this.handleResponse(response, data => data);
    }
}

export default CompaniesList;
