import * as NS from '../../namespace';
import { initial } from '../initial';

export function dataReducer(state: NS.IReduxState['data'] = initial.data, action: NS.Action) {
    switch (action.type) {
    case 'COMPANIES_LIST:LOAD_COMPANIES_COMPLETED': {
        return {
            ...state,
            companiesList: [...state.companiesList, ...action.payload.companiesList],
            nextPage: action.payload.nextPage,
            cursor: action.payload.cursor,
            companiesCount: action.payload.companiesCount
        };
    }

    case 'COMPANIES_LIST:FILTER_COMPANIES_COMPLETED':
    case 'COMPANIES_LIST:SORT_COMPANIES_COMPLETED': {
        return {
            ...state,
            companiesList: action.payload.companiesList,
            nextPage: action.payload.nextPage,
            cursor: action.payload.cursor,
            companiesCount: action.payload.companiesCount
        };
    }

    case 'COMPANIES_LIST:SET_SORT_TYPE': {
        return {
            ...state,
            sortBy: action.payload
        };
    }

    case 'COMPANIES_LIST:LOAD_FAVORITE_COMPANIES_COMPLETED': {
        return {
            ...state,
            favoriteCompanies: action.payload
        };
    }

    case 'COMPANIES_LIST:ADD_COMPANY_TO_FAVORITE_COMPLETED': {
        return {
            ...state,
            favoriteCompanies: [...state.favoriteCompanies, action.payload]
        };
    }

    case 'COMPANIES_LIST:DELETE_COMPANY_FROM_FAVORITE_COMPLETED': {
        return {
            ...state,
            favoriteCompanies: state.favoriteCompanies.filter(favorite => favorite.favoriteID !== action.payload)
        };
    }

    case 'COMPANIES_LIST:SET_DEFAULT_TABS': {
        const defaultTab = action.payload;
        return {
            ...state,
            defaultTab,
            companiesList: state.companiesList.map(item => {
                return { ...item, activeTabs: null };
            })
        };
    }

    case 'COMPANIES_LIST:SET_ACTIVE_TAB': {
        const activeTab = action.payload.activeTab;
        const companyID = action.payload.company;

        return {
            ...state,
            companiesList: state.companiesList.map(item => {
                return { ...item, activeTabs: item.id === companyID ? activeTab : item.activeTabs };
            })
        };
    }

    case 'COMPANIES_LIST:LOAD_NOTE_FROM_SERVER_COMPLETED': {
        return {
            ...state,
            noteList: action.payload
        };
    }

    case 'COMPANIES_LIST:SAVE_NOTE_ON_SERVER_COMPLETED': {
        return {
            ...state,
            noteList: [...state.noteList, action.payload]
        };
    }

    case 'COMPANIES_LIST:SEND_FEEDBACK_MESSAGE_COMPLETED': {
        return {
            ...state,
            isFeedbackMessageSentOut: true
        };
    }

    case 'COMPANIES_LIST:RESET_FEEDBACK_STATUS': {
        return {
            ...state,
            isFeedbackMessageSentOut: false
        };
    }

    case 'COMPANIES_LIST:SEND_FEEDBACK_MESSAGE_FAILED': {
        return {
            ...state,
            sendFeedbackMessageError: JSON.parse(action.error)
        };
    }

    case 'COMPANIES_LIST:CLEAR_FEEDBACK_MESSAGE_ERROR': {
        return {
            ...state,
            sendFeedbackMessageError: null
        };
    }

    default:
        return state;
    }
}
