import { autobind } from 'core-decorators';
import * as React from 'react';
import { Button, Column, Row } from 'react-foundation';

import { ISendFeedbackMessageError } from '../../../../features/companiesList/namespace';
import { MAXIMUM_TEXT_LENGTH_IN_TEXTAREA } from '../../../constants';
import { ICompany, IFeedback } from '../../../types/companiesList';

interface IProps {
    isAuthorized: boolean;
    company: ICompany;
    sendFeedbackMessageError: ISendFeedbackMessageError | null;

    sendFeedback(feedback: IFeedback): void;

    onClose?(): void;
}

interface IState {
    feedback: IFeedback;
}

class MessageForm extends React.PureComponent<IProps, IState> {

    public state: IState = {
        feedback: {
            company: this.props.company.id,
            subject: '', // TODO remove feedback subject
            message: '',
            name: '',
            email: '',
            phone: ''
        }
    };

    public render(): React.ReactNode {
        const { isAuthorized, sendFeedbackMessageError } = this.props;

        return (
            <form onSubmit={this.handleFormSubmit}>
                <h2>Отправить сообщение</h2>

                {!isAuthorized &&
                    <>
                        <Row>
                            <Column medium={2}>
                                <label htmlFor="id_text" className="middle strong text-right">Ф.И.О:</label>
                            </Column>
                            <Column medium={10}>
                                <input id="id_text" type="text" name="name" required
                                    onChange={this.handleFormFieldsChange}/>
                                {
                                    sendFeedbackMessageError?.name && (
                                        <ul className="errorlist">
                                            {sendFeedbackMessageError.name.map((error, index) =>
                                                <li key={index}>{error}</li>)}
                                        </ul>
                                    )
                                }
                            </Column>
                        </Row>
                        <Row>
                            <Column medium={2}>
                                <label htmlFor="id_email" className="middle strong text-right">Ввш e-mail:</label>
                            </Column>
                            <Column medium={10}>
                                <input id="id_email" type="email" name="email" required
                                    placeholder={'name@example.ru'}
                                    onChange={this.handleFormFieldsChange}/>
                                {
                                    sendFeedbackMessageError?.email && (
                                        <ul className="errorlist">
                                            {sendFeedbackMessageError.email.map((error, index) =>
                                                <li key={index}>{error}</li>)}
                                        </ul>
                                    )
                                }
                            </Column>
                        </Row>
                        <Row>
                            <Column medium={2}>
                                <label htmlFor="id_phone" className="middle text-right">Ввш телефон:</label>
                            </Column>
                            <Column medium={10}>
                                <input id="id_phone" type="tel" name="phone" maxLength={14}
                                    placeholder={'+7 000 000-00-00'}
                                    value={this.state.feedback.phone}
                                    onChange={this.handleFormFieldsChange}
                                />
                                {
                                    sendFeedbackMessageError?.phone && (
                                        <ul className="errorlist">
                                            {sendFeedbackMessageError.phone.map((error, index) =>
                                                <li key={index}>{error}</li>)}
                                        </ul>
                                    )
                                }
                            </Column>
                        </Row>
                    </>
                }
                <Row>
                    <Column medium={12}>
                        <label htmlFor="id_message" className="middle strong">
                            Сообщение:
                            <textarea id="id_message" name="message" rows={5} required
                                maxLength={MAXIMUM_TEXT_LENGTH_IN_TEXTAREA}
                                placeholder={'Введите текст сообщения'}
                                onChange={this.handleFormFieldsChange}
                            />
                            <div className="charcounter">
                                <div>Осталось: <b>{MAXIMUM_TEXT_LENGTH_IN_TEXTAREA - String(this.state.feedback.message).length}</b> символа(ов).</div>
                            </div>
                        </label>
                    </Column>
                </Row>
                <Row>
                    <Column small={6}>
                        <Button
                            className={'san-marino'}
                            isDisabled={String(this.state.feedback.message).length === 0}
                            isExpanded
                        >
                            Отправить
                        </Button>
                    </Column>
                    <Column small={6}>
                        <Button isExpanded onClick={this.props.onClose}>Отмена</Button>
                    </Column>
                </Row>
            </form>
        );
    }

    @autobind
    private handleFormSubmit(event: React.FormEvent): void {
        event.preventDefault();
        const feedback = { ...this.state.feedback };
        this.props.sendFeedback(feedback);
    }

    @autobind
    private handleFormFieldsChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const inputName: string = event.currentTarget.getAttribute('name')!;
        const inputValue: string = event.currentTarget.value!;
        const phoneRegExp = /^(\+|\d)\d*$|^$/;

        if (inputName === 'phone' && phoneRegExp.test(inputValue)) {
            this.setState(prevState => ({
                ...prevState,
                feedback: { ...prevState.feedback, [inputName]: inputValue }
            }));

        } else if (inputName !== 'phone') {
            this.setState(prevState => ({
                ...prevState,
                feedback: { ...prevState.feedback, [inputName]: inputValue }
            }));
        }
    }
}

export default MessageForm;
