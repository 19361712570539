import * as React from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import YandexMap, { IFormattedBoundsCoords } from '../../../../../shared/view/components/YandexMap/YandexMap';
import { IAppReduxState } from '../../../../../shared/types/app';
import { selectors as filtersSelectors } from '../../../../../services/filters';

import { ISelectedFilters } from '../../../../../services/filters/namespace';
import { actions, selectors } from '../../../redux';
import * as NS from '../../../namespace';

const initialMapSettings = {
    center: [55.751423, 37.617038],
    zoom: 12,
    behaviors: ['default', 'scrollZoom'],
    controls: ['geolocationControl', 'fullscreenControl']
};

const mapOptions = {
    minZoom: 4
};

interface IStateProps {
    organizationsPoints: NS.ICompany[];
    selectedFilters: ISelectedFilters;
}

const mapDispatch = {
    loadOrganizationsPoints: actions.loadOrganizationsPoints
};

interface IProps {
    mapVisibility: boolean;
}

type DispatchProps = typeof mapDispatch;

type Props = IStateProps & DispatchProps & IProps;

class OrganizationsMap extends React.Component<Props> {
    private boundsCoords: IFormattedBoundsCoords = { x1: 37.366, y1: 55.715, x2: 37.84, y2: 55.787 };

    public componentDidUpdate(prevProps: Props) {
        const { loadOrganizationsPoints, selectedFilters, mapVisibility } = this.props;
        if (prevProps.selectedFilters !== selectedFilters && mapVisibility) {
            loadOrganizationsPoints(this.boundsCoords);
        }
    }

    public render(): React.ReactNode {
        const { organizationsPoints } = this.props;

        return (
            <YandexMap
                initialMapSettings={initialMapSettings}
                points={organizationsPoints}
                onBoundsChange={this.handleBoundsChange}
                mapOptions={mapOptions}
            />
        );
    }

    @autobind
    private handleBoundsChange(boundsCoords: IFormattedBoundsCoords): void {
        const { loadOrganizationsPoints } = this.props;
        loadOrganizationsPoints(boundsCoords);
        this.boundsCoords = boundsCoords;
    }
}

function mapState(state: IAppReduxState): IStateProps {
    return {
        organizationsPoints: selectors.selectOrganizationsPoints(state),
        selectedFilters: filtersSelectors.selectSelectedFilters(state)
    };
}

export default connect<IStateProps, DispatchProps, {}>(mapState, mapDispatch)(OrganizationsMap);
