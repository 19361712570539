import * as React from 'react';

import { calculateCompanyAge } from '../../../../shared/helpers';

import ElementWithTooltip from '../ElementWithTooltip/ElementWithTooltip';

interface IProps {
    status: Status;
    publicationDate: string | null;
    companyName: string;
}

type Status = 'free' | 'info' | 'promo' | 'business' | 'vip';

class CompanyStatusIcon extends React.Component<IProps> {

    public render(): React.ReactNode | null {
        const { status, publicationDate, companyName } = this.props;

        const statusIcon = (
            <i className={'icon-status icon-' + status}/>
        );

        const tooltipContent = (
            <>
                {this.getTooltipContent(companyName, publicationDate, status)}
            </>
        );

        return (
            status === 'free'
                ? null
                : <ElementWithTooltip target={statusIcon} tooltipContent={tooltipContent}/>
        );
    }

    private getTooltipContent = (name: string, publicationDate: string | null, status: Status): React.ReactNode => {

        const tooltipVIPContent = (
            <>
                с максимально возможным функционалом. Количество пакетов ограничено.
                Для получения информации о возможностях данного пакета услуг{' '}
                <a className="company-status__ref"
                    href="https://www.elec-co.ru/companies_catalogue" target="_blank" rel="nofollow noreferrer">
                    свяжитесь с нашими сотрудниками.
                </a>
            </>
        );

        const tooltipContent = (
            <>
                с расширенным функционалом.{' '}
                <a className="company-status__ref"
                    href="https://www.elec-co.ru/companies_catalogue" target="_blank" rel="nofollow noreferrer">
                    Ознакомиться с возможностями существующих пакетов.
                </a>
            </>
        );

        return (
            <>
                <div className="company-status">
                    <strong>Компания</strong>&nbsp;{name}&nbsp;
                    {publicationDate && `представлена в каталоге ${calculateCompanyAge(publicationDate)}`}.
                    <br/>
                    <strong>Статус</strong>
                    <i className={'icon-status icon-' + status}/>
                    означает, что компания использует платный пакет услуг Elec.ru&nbsp;
                    {status === 'vip' ? tooltipVIPContent : tooltipContent}
                </div>
            </>
        );
    };
}

export default CompanyStatusIcon;
