import {
    AVERAGE_NUMBER_OF_DAYS_IN_MONTH,
    DAY_IN_MILLISECONDS,
    NUMBER_OF_MONTH_PER_YEAR
} from '../constants/time';

function convertCompanyAge(years: number, months: number): string {
    let yearsMessage;
    if (years === 0) {
        yearsMessage = '';
    } else if (/1$/.test(String(years)) && years !== 11) {
        yearsMessage = `${years} год`;
    } else if ((/2$|3$|4$/.test(String(years))) && years !== 12 && years !== 13 && years !== 14) {
        yearsMessage = `${years} года`;
    } else {
        yearsMessage = `${years} лет`;
    }

    let monthMessage;
    if (months === 0) {
        monthMessage = '';
    } else if (months === 1) {
        monthMessage = `${months} месяц`;
    } else if (months === 2 || months === 3 || months === 4) {
        monthMessage = `${months} месяца`;
    } else {
        monthMessage = `${months} месяцев`;
    }

    return [yearsMessage, monthMessage].filter((value) => value !== '').join(', ');
}

function calculateCompanyAge(date: string): string {
    const currentDate = new Date();

    const diff = Math.floor(currentDate.getTime() - (new Date(date)).getTime());

    const days = Math.floor(diff / DAY_IN_MILLISECONDS);
    const months = Math.floor(days / AVERAGE_NUMBER_OF_DAYS_IN_MONTH);
    const years = Math.floor(months / NUMBER_OF_MONTH_PER_YEAR);

    return convertCompanyAge(years, months - (years * NUMBER_OF_MONTH_PER_YEAR));
}

export default calculateCompanyAge;
