import { reduxEntry as userReduxEntry } from '../services/user';
import { reduxEntry as filtersReduxEntry } from '../services/filters';

import { reduxEntry as FeatureCompaniesListReduxEntry } from '../features/companiesList';
import { reduxEntry as FeatureOrganizationsMapReduxEntry } from '../features/organizationsMap';

import * as allModules from '../modules';
import { ReducersMap } from '../shared/types/redux';
import { IAppData, IAppReduxState, IModule, IReduxEntry, RootSaga } from '../shared/types/app';

import configureDeps from './configureDeps';
import { TYPES, container } from './configureIoc';
import configureStore, { createReducer } from './configureStore';

type ReducerName = keyof IAppReduxState;

function configureApp(data?: IAppData): IAppData {
    /* Prepare main app elements */
    const modules: IModule[] = Object.values(allModules);

    if (data) {
        return { ...data, modules };
    }

    const sharedReduxEntries: IReduxEntry[] = [
        userReduxEntry,
        filtersReduxEntry,
        FeatureCompaniesListReduxEntry,
        FeatureOrganizationsMapReduxEntry
    ];

    const connectedSagas: RootSaga[] = [];
    const connectedReducers: Partial<ReducersMap<IAppReduxState>> = {};

    const { runSaga, store } = configureStore();

    const dependencies = configureDeps(store);

    function connectEntryToStore({ reducers, sagas }: IReduxEntry): void {
        if (!store) {
            throw new Error('Cannot find store, while connecting module.');
        }

        if (reducers) {
            const keys = Object.keys(reducers) as ReducerName[];
            const isNeedReplace = keys.reduce(<K extends ReducerName>(acc: boolean, key: K) => {
                const featureReducer = reducers[key];
                if (!connectedReducers[key] && featureReducer) {
                    connectedReducers[key] = featureReducer;
                    return true;
                }
                return acc || false;
            }, false);

            if (isNeedReplace) {
                store.replaceReducer(createReducer(connectedReducers as ReducersMap<IAppReduxState>));
            }
        }

        if (sagas) {
            sagas.forEach((saga: RootSaga) => {
                if (!connectedSagas.includes(saga) && runSaga) {
                    runSaga(saga(dependencies));
                    connectedSagas.push(saga);
                }
            });
        }
    }

    try {
        container.getAll(TYPES.Store);
        container.rebind(TYPES.connectEntryToStore).toConstantValue(connectEntryToStore);
        container.rebind(TYPES.Store).toConstantValue(store);
    } catch {
        container.bind(TYPES.connectEntryToStore).toConstantValue(connectEntryToStore);
        container.bind(TYPES.Store).toConstantValue(store);
    }

    sharedReduxEntries.forEach(connectEntryToStore);
    modules.forEach((module: IModule) => {
        if (module.getReduxEntry) {
            connectEntryToStore(module.getReduxEntry());
        }
    });

    return { modules, store };
}

export default configureApp;
