import { makeCommunicationActionCreators } from 'redux-make-communication';
import * as NS from '../../namespace';

export const { execute: loadFilters, success: loadFiltersCompleted, fail: loadFiltersFailed } =
    makeCommunicationActionCreators<NS.ILoadFilters, NS.ILoadFiltersCompleted, NS.ILoadFiltersFailed>(
        'FILTERS:LOAD_FILTERS',
        'FILTERS:LOAD_FILTERS_COMPLETED',
        'FILTERS:LOAD_FILTERS_FAILED'
    );

export const { execute: loadDependentFilters, success: loadDependentFiltersCompleted, fail: loadDependentFiltersFailed } =
    makeCommunicationActionCreators<NS.ILoadDependentFilters, NS.ILoadDependentFiltersCompleted, NS.ILoadDependentFiltersFailed>(
        'FILTERS:LOAD_DEPENDENT_FILTERS',
        'FILTERS:LOAD_DEPENDENT_FILTERS_COMPLETED',
        'FILTERS:LOAD_DEPENDENT_FILTERS_FAILED'
    );

export const { execute: loadInsideFilters, success: loadInsideFiltersCompleted, fail: loadInsideFiltersFailed } =
    makeCommunicationActionCreators<NS.ILoadInsideFilters, NS.ILoadInsideFiltersCompleted, NS.ILoadInsideFiltersFailed>(
        'FILTERS:LOAD_INSIDE_FILTERS',
        'FILTERS:LOAD_INSIDE_FILTERS_COMPLETED',
        'FILTERS:LOAD_INSIDE_FILTERS_FAILED'
    );
