import * as React from 'react';
import { autobind } from 'core-decorators';
import { Manager, Popper, Reference } from 'react-popper';

interface IProps {
    target: JSX.Element;
    tooltipContent: JSX.Element;
}

interface IState {
    isTooltipOpen: boolean;
}

class ElementWithTooltip extends React.Component<IProps, IState> {

    public state = {
        isTooltipOpen: false
    };

    public render(): React.ReactNode {
        const { target, tooltipContent } = this.props;
        const { isTooltipOpen } = this.state;
        return (
            <Manager>
                <div onMouseEnter={this.handleManagerMouseEnter} onMouseLeave={this.handleManagerMouseLeave}>
                    <Reference>
                        {({ ref }) => (<div ref={ref}>{target}</div>)}
                    </Reference>
                    {isTooltipOpen && (
                        <Popper placement="bottom-start">
                            {({ placement, ref, style }) => (
                                <div ref={ref} style={style} data-placement={placement}>
                                    {tooltipContent}
                                </div>
                            )}
                        </Popper>
                    )}
                </div>
            </Manager>
        );
    }

    @autobind
    private handleManagerMouseEnter(): void {
        this.setState({ isTooltipOpen: true });
    }

    @autobind
    private handleManagerMouseLeave(): void {
        this.setState({ isTooltipOpen: false });
    }
}

export default ElementWithTooltip;
