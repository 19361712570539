import * as React from 'react';
import { block } from 'bem-cn';
import { autobind } from 'core-decorators';

import { FilterType, IFilterValue } from '../../../../services/filters/namespace';
import { Checkbox } from '../../../../shared/view/elements';

import './FilterListItem.scss';

const b = block('filter-list-item');

interface IProps {
    item: IFilterValue;
    invisible?: boolean;
    filterType: FilterType;

    onFilterItemClick(id: number, eventTarget: string, filterType: FilterType): void;
}

class FilterListItem extends React.Component<IProps> {

    public render(): React.ReactNode {
        const { item, invisible, onFilterItemClick, filterType } = this.props;

        return (
            <div className={b({ invisible })}>
                <Checkbox
                    name="checkbox"
                    value="checkbox"
                    checked={item.checked}
                    disabled={item.disabled}
                    label={item.title}
                    isLabelUnderlined={item.hasChildren}
                    onChange={this.handleCheckboxChange.bind(this, item)}
                    onLabelClick={item.hasChildren ? this.handleCheckboxLabelClick.bind(this, item) : undefined}
                />
                {item.hasChildren && item.children && (
                    <div className={b('inner-wrapper', { visible: item.childrenVisibility })}>
                        {item.children.map((innerItem, index) => {
                            return (
                                <FilterListItem
                                    item={innerItem}
                                    filterType={filterType}
                                    onFilterItemClick={onFilterItemClick}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }

    @autobind
    private handleCheckboxChange(filterItem: IFilterValue): void {
        const { onFilterItemClick, filterType } = this.props;
        onFilterItemClick(filterItem.id, 'checkbox', filterType);
    }

    @autobind
    private handleCheckboxLabelClick(filterItem: IFilterValue): void {
        const { onFilterItemClick, filterType } = this.props;
        onFilterItemClick(filterItem.id, 'label', filterType);
    }
}

export { FilterListItem };
