import { IReduxEntry } from '../../shared/types/app';

import { actions, initial, reducer, selectors } from './redux';
import * as namespace from './namespace';

export { namespace, selectors, actions, initial };

export const reduxEntry: IReduxEntry = {
    reducers: { user: reducer }
};
