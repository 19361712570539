import { AxiosResponse } from 'axios';
import { ApiClient } from '../../../../../api';
import { makeApiError } from '../errors';

import { Converter, IApiResponse, IErrorResponse, IHeaders, ServerErrorKey } from '../types';
import { isDataResponse } from '../types/guards';
import { Cookies, ICookies } from './Cookies';

export function isErrorStatus(status: number): boolean {
    return status >= 400 && status <= 500;
}

class BaseApi {
    protected actions: ApiClient;
    protected cookies: ICookies;

    constructor(actions: ApiClient) {
        this.actions = actions;
        this.cookies = new Cookies();
    }

    protected handleResponse<ResponseData, E extends ServerErrorKey, ErrorPayload>(
        response: AxiosResponse<IApiResponse<ResponseData, E, ErrorPayload>>,
        converter?: null,
        errorPayloadConverter?: (x: ErrorPayload) => any
    ): void;
    protected handleResponse<ResponseData, E extends ServerErrorKey, ErrorPayload, Result>(
        response: AxiosResponse<IApiResponse<ResponseData, E, ErrorPayload>>,
        converter: Converter<ResponseData, Result>,
        errorPayloadConverter?: (x: ErrorPayload) => any
    ): Result;
    protected handleResponse<ResponseData, E extends ServerErrorKey, ErrorPayload, Result>(
        response: AxiosResponse<IApiResponse<ResponseData, E, ErrorPayload>>,
        converter?: Converter<ResponseData, Result> | null,
        errorPayloadConverter?: (x: ErrorPayload) => any
    ): Result | void {
        const responseData = (() => {
            if (isDataResponse<ResponseData>(response.data)) {
                return response.data.data;
            } else {
                return response.data;
            }
        })();

        // TODO: Add typings
        if (isErrorStatus(response.status)) {
            const request: XMLHttpRequest = (response as any).request; // AxiosXHR interface hasn't request field
            throw makeApiError<ErrorPayload, any>({
                config: response.config,
                status: response.status,
                data: responseData as IErrorResponse<ErrorPayload>,
                headers: response.headers as IHeaders,
                request,
                response: request.response,
                payloadConverter: errorPayloadConverter
            });
        }
        if (converter) {
            return converter(responseData as ResponseData);
        }
    }
}

export default BaseApi;
