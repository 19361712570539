import React from 'react';

import { CompaniesList } from '../../../../../features/companiesList';

import CompaniesListHeader from '../../../../shared/CompaniesListHeader/CompaniesListHeader';

const CompaniesCatalogLayout: React.FC = () => {
    return (
        <CompaniesList header={CompaniesListHeader}/>
    );
};

export default CompaniesCatalogLayout;
