import { apiClient } from '../../../../api';
import { CompaniesList, Filters, OrganizationsMap } from './entities';

class Api {
    public companies: CompaniesList;
    public organizationsMap: OrganizationsMap;
    public filters: Filters;

    constructor() {
        this.companies = new CompaniesList(apiClient);
        this.organizationsMap = new OrganizationsMap(apiClient);
        this.filters = new Filters(apiClient);
    }
}

export default Api;
