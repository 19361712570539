import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { selectSectionParentId, selectSelectedFilters } from '../../../../services/filters/redux/selectors';
import { IDependencies } from '../../../../shared/types/app';
import { getErrorMsg } from '../../../../shared/helpers';

import * as actions from '../actions';
import * as NS from '../../namespace';

const loadOrganizationsPointsType: NS.ILoadOrganizationsPoints['type'] = 'ORGANIZATIONS_MAP:LOAD_ORGANIZATIONS_POINTS';

function* loadOrganizationsPointsSaga({ api }: IDependencies, action: NS.ILoadOrganizationsPoints) {
    try {
        const boundsCoords = action.payload;
        const filters = yield select(selectSelectedFilters);
        const sectionParentId = yield select(selectSectionParentId);
        const organizations =
            yield call(api.organizationsMap.getOrganizationsPoints, boundsCoords, filters, sectionParentId);
        yield put(actions.loadOrganizationsPointsCompleted(organizations));
    } catch (error) {
        const errorMsg = getErrorMsg(error);
        yield put(actions.loadOrganizationsPointsFailed(errorMsg));
    }
}

function rootSaga(deps: IDependencies) {
    return function* saga(): SagaIterator {
        yield takeLatest(loadOrganizationsPointsType, loadOrganizationsPointsSaga, deps);
    };
}

export { rootSaga };
