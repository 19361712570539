import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { IAppData } from '../shared/types/app';
import createRoutes from './routes';

function ClientApp({ modules, store }: IAppData): React.ReactElement {
    return (
        <Provider store={store}>
            <BrowserRouter>
                {createRoutes(modules)}
            </BrowserRouter>
        </Provider>
    );
}

export const App = ClientApp;
