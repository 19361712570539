import * as React from 'react';
import { createPortal } from 'react-dom';

interface IProps {
    parent: Element;
}

class Portal extends React.Component<React.PropsWithChildren<IProps>> {
    public render(): React.ReactNode {
        const { parent, children } = this.props;

        return createPortal(children, parent);
    }
}

export default Portal;
