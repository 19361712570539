import * as React from 'react';
import { block } from 'bem-cn';
import { MapOptions as IMapOptions, Map, ObjectManager, ObjectManagerFeature, YMaps, ZoomControl } from 'react-yandex-maps';
import { autobind } from 'core-decorators';

import { SubscribeType } from '../../../types/models';

import './YandexMap.scss';

const b = block('yandex-map');

const objectManagerOptions = {
    preset: 'islands#invertedOrangeClusterIcons',
    clusterize: true
};

const pointsOptions = {
    hideIconOnBalloonOpen: true
};

const clusterOptions = {
    disableClickZoom: false
};

const pointsColorsByStatus = {
    'free': { preset: 'islands#grayIcon' },
    'info': { preset: 'islands#yellowIcon' },
    'promo': { preset: 'islands#greenIcon' },
    'business': { preset: 'islands#orangeIcon' },
    'vip': { preset: 'islands#redIcon' }
};

interface IProps {
    points: IOrganization[];
    initialMapSettings: IMapSettings;
    mapOptions: IMapOptions;

    onBoundsChange(boundsCoords: IFormattedBoundsCoords): void;
}

interface IFormattedBoundsCoords {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
}

interface IOrganization {
    name: string;
    description: string;
    address: string;
    url: string;
    subscribeType: SubscribeType;
    coords: number[];
}

interface IMapSettings {
    center: number[];
    zoom: number;
    behaviors: string[];
}

// there are no coorrect types in library react-yandex-maps
interface IYandexMap {
    getBounds(): number[][];
}

class YandexMap extends React.Component<IProps> {
    private mapRef: React.RefObject<IYandexMap> = React.createRef();

    public render(): React.ReactNode {
        const { points, initialMapSettings, mapOptions } = this.props;

        const pointsCollection = points.map((point, index) => {
            const { name, url, coords, subscribeType } = point;
            const properties = {
                hintContent: name,
                balloonContentHeader: name,
                balloonContentFooter: `<a href="${url}">${url}</a>`
            };
            const coordinates = [coords[0], coords[1]];
            return {
                id: index,
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates
                },
                properties,
                options: pointsColorsByStatus[subscribeType]
            };
        }) as ObjectManagerFeature[];

        return (
            <div className={b()}>
                <YMaps
                    query={{
                        apikey: '770ce412-031c-459c-9265-c64ef01dcc11'
                    }}
                >
                    <Map
                        // there are broken types, so used "as any"
                        instanceRef={this.mapRef as any}
                        state={initialMapSettings}
                        className={b('container')}
                        onBoundsChange={this.handleBoundsChange}
                        modules={['package.clusters']}
                        options={mapOptions}
                    >
                        <ObjectManager
                            options={objectManagerOptions}
                            objects={pointsOptions}
                            clusters={clusterOptions}
                            features={{
                                type: 'FeatureCollection',
                                features: pointsCollection
                            }}
                        />
                        <ZoomControl options={{ size: 'large', position: { top: 50, left: 10 }}}/>
                    </Map>
                </YMaps>
            </div>
        );
    }

    @autobind
    private handleBoundsChange(): void {
        const { onBoundsChange } = this.props;
        if (this.mapRef.current) {
            const boundsCoords = this.mapRef.current.getBounds();
            const formattedBoundsCoords = {
                x1: boundsCoords[0][1],
                y1: boundsCoords[0][0],
                x2: boundsCoords[1][1],
                y2: boundsCoords[1][0]
            };
            onBoundsChange(formattedBoundsCoords);
        }
    }
}

export default YandexMap;
export { IFormattedBoundsCoords };
