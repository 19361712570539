import * as React from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import SectionFilters from '../../../../../shared/view/components/SectionFilters/SectionFilters';
import { ExtraFilters } from '../../../../../shared/view/components';
import { IAppReduxState } from '../../../../../shared/types/app';

import { actions, selectors } from '../../../redux';
import * as NS from '../../../namespace';

interface IStateProps {
    filters: NS.IFilters | null;
    selectedFilters: NS.ISelectedFilters;
    sectionParentId: number;
}

interface IProps {
    onFiltersClick(): void;
}

const mapDispatch = {
    loadFilters: actions.loadFilters,
    loadDependentFilters: actions.loadDependentFilters,
    loadInsideFilters: actions.loadInsideFilters,
    openInsideFilters: actions.openInsideFilters,
    toggleFiltersChecks: actions.toggleFiltersChecks,
    resetFiltersChecks: actions.resetFiltersChecks,
    resetInsideFiltersChecks: actions.resetInsideFiltersChecks,
    getSectionParentId: actions.getSectionParentId
};

type DispatchProps = typeof mapDispatch;

type Props = IStateProps & DispatchProps & IProps;

class Filters extends React.PureComponent<Props> {

    public componentDidMount(): void {
        const { getSectionParentId } = this.props;
        getSectionParentId();
    }

    public componentDidUpdate(prevProps: IStateProps): void {
        const { sectionParentId, loadFilters } = this.props;
        if (prevProps.sectionParentId !== sectionParentId && sectionParentId > 0) {
            loadFilters(sectionParentId);
        }
    }

    public render(): React.ReactNode {
        const { filters } = this.props;
        const sectionFilters = filters ? filters.rubrica.values : [];

        return (
            <>
                {sectionFilters && sectionFilters.length !== 0 &&
                <SectionFilters
                    itemsList={sectionFilters}
                    headerText="Раздел"
                    onFilterItemClick={this.handleFilterItemClick}
                />
                }
                {filters &&
                <ExtraFilters
                    filters={filters}
                    onFilterItemClick={this.handleFilterItemClick}
                    onResetButtonClick={this.handleResetButtonClick}
                />
                }
            </>
        );
    }

    @autobind
    private handleFilterItemClick(id: number, eventTarget: string, filterType: NS.FilterType): void {
        const {
            onFiltersClick, toggleFiltersChecks, loadInsideFilters,
            openInsideFilters, resetInsideFiltersChecks, filters,
            loadDependentFilters, sectionParentId
        } = this.props;
        const innerFilters = filters?.[filterType]?.values;
        if (eventTarget === 'label') {
            if (innerFilters && this.isChildrenNotExist(id, innerFilters)) {
                loadInsideFilters({ id, filterType });
            }
            if (innerFilters && this.isChildrenOpenAndChecked(id, innerFilters)) {
                resetInsideFiltersChecks({ id, filterType });
                onFiltersClick();
            }
            openInsideFilters({ id, filterType });
        }
        if (eventTarget === 'checkbox') {
            toggleFiltersChecks({ id, filterType });
            onFiltersClick();
            loadDependentFilters(sectionParentId);
        }
    }

    @autobind
    private handleResetButtonClick(): void {
        const { resetFiltersChecks, onFiltersClick } = this.props;
        resetFiltersChecks();
        onFiltersClick();
    }

    private isChildrenNotExist(id: number, filters: NS.IFilterValue[], acc: boolean[] = []): boolean {
        filters.map(value => {
            if (value.id === id) {
                if (value.children === undefined || value.children.length === 0) {
                    acc.push(true);
                } else {
                    acc.push(false);
                }
            } else if (value.children) {
                this.isChildrenNotExist(id, value.children, acc);
            }
            return value;
        });
        return Boolean(acc.filter(item => item === true)[0]);
    }

    private isChildrenOpenAndChecked(id: number, filters: NS.IFilterValue[], acc: boolean[] = []): boolean {
        filters.map(value => {
            if (value.id === id) {
                if (value.children && value.childrenVisibility) {
                    const isSomeChildrenChecked = this.findCheckedChildren(value.children).filter(item => item === true)[0];
                    if (isSomeChildrenChecked) {
                        acc.push(true);
                    }
                } else {
                    acc.push(false);
                }
            } else if (value.children) {
                this.isChildrenOpenAndChecked(id, value.children, acc);
            }
            return value;
        });
        return Boolean(acc.filter(item => item === true)[0]);
    }

    private findCheckedChildren(filters: NS.IFilterValue[]): boolean[] {
        return filters.reduce((acc, value) => {
            if (value.checked) {
                return [...acc, true];
            } else if (value.children) {
                return [...acc, ...this.findCheckedChildren(value.children)];
            }
            return acc;
        }, []);
    }
}

function mapState(state: IAppReduxState): IStateProps {
    return {
        filters: selectors.selectFilters(state),
        selectedFilters: selectors.selectSelectedFilters(state),
        sectionParentId: selectors.selectSectionParentId(state)
    };
}

export default connect<IStateProps, DispatchProps, {}>(mapState, mapDispatch)(Filters);
