import { combineReducers } from 'redux';

import * as NS from '../../namespace';
import { dataReducer } from './data';

const reducer = combineReducers<NS.IReduxState>({
    data: dataReducer
});

export default reducer;
