import * as NS from '../../namespace';
import { ExcludingTabType, IActiveTab } from '../../../../shared/types/companiesList';

export function setSortType(payload: NS.SortType): NS.ISetSortType {
    return { type: 'COMPANIES_LIST:SET_SORT_TYPE', payload };
}

export function setDefaultTabs(payload: ExcludingTabType): NS.ISetDefaultTabs {
    return { type: 'COMPANIES_LIST:SET_DEFAULT_TABS', payload };
}

export function setActiveTab(payload: IActiveTab) {
    return { type: 'COMPANIES_LIST:SET_ACTIVE_TAB', payload };
}

export function resetFeedbackStatus() {
    return { type: 'COMPANIES_LIST:RESET_FEEDBACK_STATUS' };
}

export function clearFeedbackMessageError() {
    return { type: 'COMPANIES_LIST:CLEAR_FEEDBACK_MESSAGE_ERROR' };
}
