import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import Truncate from 'react-truncate';
import { block } from 'bem-cn';

import './TruncatingContainer.scss';

interface IProps {
    children: React.ReactNode;
    lines: number;
}

interface IState {
    truncated: boolean;
}

const b = block('truncating-container');

class TruncatingContainer extends Component<IProps, IState> {

    public state: IState = {
        truncated: true
    };

    public render(): React.ReactNode {
        const { children, lines } = this.props;
        const { truncated } = this.state;

        return (
            <Truncate
                className={b()}
                lines={truncated && lines}
                ellipsis={<span>…<a className={b('more')} onClick={this.handleTruncateClick}>ещё</a></span>}
            >
                {children}
            </Truncate>
        );
    }

    @autobind
    private handleTruncateClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
        event.preventDefault();
        this.setState({
            truncated: false
        });
    }
}

export default TruncatingContainer;
