import * as React from 'react';
import { block } from 'bem-cn';

import './Checkbox.scss';

const b = block('checkbox');

interface IProps {
    name: string;
    value: string;
    label: string;
    checked?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    isLabelUnderlined?: boolean;
    labelCount?: number;
    invisible?: boolean;

    onChange?(): void;

    onLabelClick?(): void;
}

class Checkbox extends React.PureComponent<IProps> {

    public render(): React.ReactNode {
        const {
            checked,
            disabled,
            name,
            value,
            label,
            readOnly,
            onChange,
            isLabelUnderlined,
            onLabelClick,
            labelCount,
            invisible
        } = this.props;
        const onLabelClickHandler = disabled ? undefined : onLabelClick;

        return (
            <div className={b({ invisible, disabled })}>
                <label className={b('label-wrapper', { disabled })}>
                    <input
                        type="checkbox"
                        value={value}
                        className={b('input', { disabled })}
                        name={name}
                        checked={checked}
                        disabled={disabled}
                        readOnly={readOnly}
                        onChange={onChange}
                    />
                    {onLabelClickHandler ?
                        (
                            <span
                                className={b('label', { disabled, 'underlined': isLabelUnderlined })}
                                onClick={onLabelClick}>
                                {label}
                            </span>
                        )
                        :
                        (
                            <span className={b('label', { 'disabled': disabled, 'underlined': isLabelUnderlined })}>
                                {label}
                            </span>
                        )}
                    {labelCount ? `(${labelCount})` : ''}
                </label>
            </div>
        );
    }
}

export default Checkbox;
