import { IReduxEntry } from '../../shared/types/app';

import * as namespace from './namespace';
import { actions, reducer, rootSaga, selectors } from './redux';

export { default as Filters } from './view/containers/Filters/Filters';

export { namespace, selectors, actions };

export const reduxEntry: IReduxEntry = {
    reducers: { filters: reducer },
    sagas: [rootSaga]
};
