import { IReduxEntry } from '../../shared/types/app';

import * as namespace from './namespace';
import { actions, reducer, rootSaga, selectors } from './redux';

export { default as CompaniesList } from './view/containers/CompaniesList/CompaniesList';

export { namespace, selectors, actions };

export const reduxEntry: IReduxEntry = {
    reducers: { companiesList: reducer },
    sagas: [rootSaga]
};
