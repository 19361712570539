import * as React from 'react';
import { Alignments, Button, Cell, Grid, GutterTypes } from 'react-foundation';

interface IProps {
    onClose(): void;
}

class SuccessMessage extends React.Component<IProps> {

    public render(): React.ReactNode {
        return (
            <Grid vertical gutters={GutterTypes.MARGIN} centerAlign>
                <Cell>
                    <h2>Сообщение отправлено</h2>
                </Cell>
                <Cell small={3} selfAlignX={Alignments.CENTER}>
                    <Button onClick={this.props.onClose} isExpanded>Закрыть</Button>
                </Cell>
            </Grid>
        );
    }
}

export default SuccessMessage;
