import React from 'react';
import { Route } from 'react-router-dom';

import routes from '../../modules/routes';
import { IModule } from '../../shared/types/app';

import { CompaniesCatalogLayout } from './view/components';

const CompaniesCatalog: IModule = {

    getRoutes(): React.ReactElement {
        return (
            <Route
                key={routes.catalog.getElementKey()}
                path={routes.catalog.getRoutePath()}
                component={CompaniesCatalogLayout}
            />
        );
    }
};

export default CompaniesCatalog;
