function convertSnakeToCamelCase(str: string): string {
    return str.replace(
        /([-_][a-z])/g,
        (group) => group.toUpperCase()
            .replace('-', '')
            .replace('_', '')
    );
}

export default convertSnakeToCamelCase;
