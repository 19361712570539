import { block } from 'bem-cn';
import { autobind } from 'core-decorators';
import * as React from 'react';
import Modal from 'shared/Modal';

import { ISendFeedbackMessageError } from '../../../../features/companiesList/namespace';
import {
    ExcludingTabType,
    IActiveTab,
    ICompany,
    IContact,
    IFeedback,
    INote,
    TabType
} from '../../../types/companiesList';

import MessageForm from '../MessageForm/MessageForm';
import SuccessMessage from '../MessageForm/SuccessMessage';
import Content from './Content/Content';
import './Tabs.scss';

interface IProps {
    tabs: ITab[];
    defaultTab: ExcludingTabType;
    tabsContent: IContent;
    isAuthorized: boolean;
    company: ICompany;
    companyID: number;
    savedNote: string;
    isFeedbackMessageSentOut: boolean;
    sendFeedbackMessageError: ISendFeedbackMessageError | null;

    onSaveNoteToServer(note: INote): void;

    sendFeedback(feedback: IFeedback): void;

    onSetActiveTab(activeTab: IActiveTab): void;

    resetFeedbackStatus(): void;

    clearFeedbackMessageError(): void;
}

interface IState {
    activeTab: ExcludingTabType;
    isModalShown: boolean;
}

interface IContent {
    description: string;
    address: string;
    phones: IContact[] | null;
}

interface ITab {
    type: TabType;
    title: string;
}

const b = block('tabs');

class Tabs extends React.Component<IProps, IState> {

    public state: IState = {
        activeTab: this.props.defaultTab,
        isModalShown: false
    };

    public componentDidUpdate(_: IProps, prevState: IState): void {
        const { defaultTab } = this.props;
        if (defaultTab !== prevState.activeTab) {
            this.setState({ activeTab: defaultTab });
        }
    }

    public render(): React.ReactNode {
        const {
            tabs,
            tabsContent,
            isAuthorized,
            savedNote,
            onSaveNoteToServer,
            companyID,
            company,
            sendFeedback,
            isFeedbackMessageSentOut,
            sendFeedbackMessageError
        } = this.props;
        const { activeTab, isModalShown } = this.state;
        const tabsForGuest = tabs.filter((item) => item.type !== 'note');
        return (
            <div className={b()}>
                <div className={b('content')}>
                    <Content
                        content={tabsContent}
                        type={activeTab}
                        savedNote={savedNote}
                        onSaveNoteToServer={onSaveNoteToServer}
                        companyID={companyID}
                    />
                </div>
                <div className={b('line')}/>
                {(isAuthorized ? tabs : tabsForGuest)
                    .map(({ title, type }: ITab) =>
                        type === 'sendMessage'
                            ? (
                                <div className={b('tab')} key={type}>
                                    <div onClick={this.handleSendMessageClick}>
                                        <span className={b('text')}>{title}</span>
                                    </div>
                                    {isModalShown &&
                                    <Modal open={isModalShown} onClose={this.handleMessageFormClose} isModal={true}>
                                        {isFeedbackMessageSentOut ?
                                            <SuccessMessage onClose={this.handleMessageFormClose}/>
                                            :
                                            <MessageForm
                                                onClose={this.handleMessageFormClose}
                                                isAuthorized={isAuthorized}
                                                company={company}
                                                sendFeedback={sendFeedback}
                                                sendFeedbackMessageError={sendFeedbackMessageError}
                                            />
                                        }
                                    </Modal>
                                    }
                                </div>
                            )
                            : (
                                <div
                                    className={b('tab', { active: activeTab === type })}
                                    key={type}
                                    onClick={this.makeTabClickHandler(type)}
                                >
                                    <span className={b('text')}>{title}</span>
                                </div>
                            )
                    )
                }
            </div>
        );
    }

    @autobind
    private handleSendMessageClick(): void {
        this.setState({ isModalShown: true });
    }

    @autobind
    private handleMessageFormClose(): void {
        const { resetFeedbackStatus, clearFeedbackMessageError } = this.props;
        this.setState({ isModalShown: false });
        resetFeedbackStatus();
        clearFeedbackMessageError();
    }

    private makeTabClickHandler(type: ExcludingTabType) {
        const { onSetActiveTab, company } = this.props;
        return () =>
            onSetActiveTab({ activeTab: type, company: company.id });
    }
}

export { Tabs, IContent, ITab, ExcludingTabType };
