import { SortType } from '../../../features/companiesList/namespace';
import { IFilterValue, IFilters, ISelectedFilters } from '../../filters/namespace';
import { SubscribeType } from '../../../shared/types/models';
import { ExcludingTabType, IContact, IFeedback } from '../../../shared/types/companiesList';
import { camelToSnakeCase, snakeToCamelCase } from '../../../shared/helpers';

import {
    IFavoriteCompany,
    IServerCompaniesList,
    IServerContact,
    IServerFavoriteCompany,
    IServerFilterValue,
    IServerFilters
} from '../types';

function convertServerSubscribeType(type: number): SubscribeType {
    const subscribes: { [key: number]: SubscribeType } = { 1: 'free', 2: 'info', 3: 'promo', 4: 'business', 5: 'vip' };
    return subscribes[type];
}

function convertServerContacts(contacts: IServerContact[]): IContact[] {
    return contacts.map(contact => {
        return {
            phoneNumber: contact.value,
            comment: contact.comment
        };
    });
}

function getNextPage(url: string | null): string | null {
    if (url === null) {
        return null;
    }

    const pagePattern = /page=(\d+)/g;
    const match = pagePattern.exec(url);
    return match ? match[1] : '0';

}

export function convertServerCompaniesList(companiesData: IServerCompaniesList) {
    const { results } = companiesData;
    const activeTabs: ExcludingTabType | null = null;
    const companiesList = results.map(serverCompany => {
        const { title, address, tizer, logo, url, is_best, status, pub_date, phones, id } = serverCompany;
        return {
            address,
            logo,
            url,
            name: title,
            description: tizer,
            publicationDate: pub_date,
            isBest: is_best,
            id,
            subscribeType: convertServerSubscribeType(status),
            contacts: phones ? convertServerContacts(phones) : [],
            activeTabs
        };
    });
    return {
        nextPage: getNextPage(companiesData.next),
        cursor: companiesData.cursor,
        companiesCount: companiesData.count,
        companiesList
    };
}

export function convertFilters(filters: IServerFilterValue[]): IFilterValue[] {
    return filters.map(filter => {
        const { id, count, title, has_children } = filter;
        return {
            id,
            count,
            title,
            hasChildren: has_children
        };
    });
}

export function convertServerFilters(filters: IServerFilters): IFilters {
    return Object.entries(filters).reduce((acc, [key, filter]) => {
        const convertedValues = filter.values ? convertFilters(filter.values) : [];
        const parentId = filter.parent_id ? filter.parent_id : null;
        const convertedFilter = {
            title: filter.title,
            type: filter.type,
            values: convertedValues,
            section: filter.rubrica,
            parentId
        };
        return {
            ...acc,
            [snakeToCamelCase(key)]: convertedFilter
        };
    }, {});
}

export function convertServerInsideFilters(filters: IServerFilters): IFilterValue[] {
    const sectionKey = Object.keys(filters)[0];
    const newValues = filters[sectionKey].values;
    return convertFilters(newValues ? newValues : []);
}

export function convertServerFavorite(favorites: IServerFavoriteCompany[]): IFavoriteCompany[] {
    return favorites.map<IFavoriteCompany>(favorite => ({ favoriteID: favorite.id, companyID: favorite.object_id }));
}

export function convertServerAddFavorite(favorite: IServerFavoriteCompany): IFavoriteCompany {
    return {
        favoriteID: favorite.id,
        companyID: favorite.object_id
    };
}

export function convertClientToServerFeedback(feedback: IFeedback) {
    return {
        company: feedback.company,
        name: feedback.name,
        email: feedback.email,
        phone: feedback.phone,
        subject: feedback.subject,
        message: feedback.message
    };
}

export function convertFiltersToRequestString(filters: ISelectedFilters): string {
    return Object.keys(filters).reduce((acc, filter) => {
        if (filters[filter].length !== 0) {
            const innerRequestString = filters[filter].reduce((accumulator, id) => {
                const convertedFilterType = camelToSnakeCase(filter);
                return accumulator.concat(`&${convertedFilterType}=${id}`);
            }, '');
            return acc.concat(innerRequestString);
        }
        return acc;
    }, '');
}

export function convertDataToRequestString(nextPage: string | null, sortBy: SortType, filters: ISelectedFilters): string {
    const pageNumber = nextPage === null || nextPage === '0' ? '' : `&page=${nextPage}`;
    const parentID = window.parentId ? `&parent_id=${window.parentId}` : '';
    const filtersParameters = convertFiltersToRequestString(filters);

    return 'ordering='.concat(sortBy === 'regDate' ? 'ogrn_date' : sortBy, pageNumber, parentID, filtersParameters);
}
