import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import App from '../modules/App/view/App';
import routes from '../modules/routes';
import { IModule } from '../shared/types/app';

function getRoutes(modules: IModule[]): React.ReactElement<RouteComponentProps<any>> {
    return (
        <Route path="/">
            <App>
                <Switch>
                    {modules.map(module => module.getRoutes ? module.getRoutes() : null)}
                    <Redirect to={routes.catalog.getRedirectPath()}/>
                </Switch>
            </App>
        </Route>
    );
}

export default getRoutes;
