import { IReduxState } from '../namespace';

export const initial: IReduxState = {
    data: {
        companiesList: [],
        nextPage: null,
        cursor: '',
        sortBy: 'order',
        favoriteCompanies: [],
        noteList: [],
        companiesCount: 0,
        defaultTab: 'description',
        isFeedbackMessageSentOut: false,
        sendFeedbackMessageError: null
    }
};
