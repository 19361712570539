import { IAppReduxState } from '../../../shared/types/app';

import * as NS from '../namespace';

export function selectState(state: IAppReduxState): NS.IReduxState {
    return state.filters;
}

export function selectFilters(state: IAppReduxState): NS.IFilters | null {
    const filters = selectState(state).data.filters;
    return filters || null;
}

export function selectSelectedFilters(state: IAppReduxState): NS.ISelectedFilters {
    return selectState(state).data.selectedFilters;
}

export function selectSectionParentId(state: IAppReduxState): number {
    return selectState(state).data.sectionParentId;
}
