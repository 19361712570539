import { autobind } from 'core-decorators';

import { FilterType, IFilterValue, IFilters, ISelectedFilters } from '../../filters/namespace';

import { convertFiltersToRequestString, convertServerFilters, convertServerInsideFilters } from '../converters';
import { IServerFilters } from '../types';
import BaseApi from './BaseApi';

class Filters extends BaseApi {

    @autobind
    public async loadFilters(filters: ISelectedFilters, sectionParentId: number): Promise<IFilters> {
        const filtersParameters = convertFiltersToRequestString(filters);
        const parentID = sectionParentId ? `parent_id=${sectionParentId}` : '';
        const response = await this.actions.get<IServerFilters>(
            `/catalog/filters/?${parentID}${filtersParameters}`
        );
        return this.handleResponse(response, convertServerFilters);
    }

    @autobind
    public async loadDependentFilters(filters: ISelectedFilters, sectionParentId: number): Promise<IFilters> {
        const filtersParameters = convertFiltersToRequestString(filters);
        const parentID = sectionParentId ? `parent_id=${sectionParentId}` : '';
        const response = await this.actions.get<IServerFilters>(
            `/catalog/filters/?${parentID}${filtersParameters}`
        );
        return this.handleResponse(response, convertServerFilters);
    }

    @autobind
    public async loadInsideFilters(id: number, filterType: FilterType, sectionParentId: number):
        Promise<IFilterValue[]> {
        const parentID = sectionParentId ? `parent_id=${sectionParentId}` : '';
        const requestString = filterType === 'geo'
            ? `/catalog/filters_${filterType}/?${parentID}&geo_id=${id}`
            : `/catalog/filters_rubrica/?parent_id=${id}`;
        const response = await this.actions.get<IServerFilters>(
            `${requestString}`
        );
        return this.handleResponse(response, convertServerInsideFilters);
    }
}

export default Filters;
